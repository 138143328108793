import React from 'react';
import './App.scss';
import { ProvideAuth, PrivateRoute, SecureLogin, SecureLogout } from '../Shared/Utils/ProvideAuth';
import ToasterAlert from '../Shared/Utils/ToasterUtils';
import PageNotFound from '../Pages/PagesNotFound/PagesNotFound';
import IconComponent from '../Shared/Components/IconComponent/IconComponent';
import Header from '../Shared/Components/Header/Header';
import Loader from '../Shared/Utils/Loader/Loader';
import Home from '../Pages/Home/Home';
import {
  HashRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import AdminMain from '../Pages/Admin/AdminMain';
import MeetingDetails from '../Pages/Home/MeetingDetails/MeetingDetails';
import KOLProfileDetails from '../Pages/Home/KOL_Profile_Details/KOLProfileDetails';
import Engagement from '../Pages/Home/Engagement/Engagement';
import Conference from '../Pages/Home/Conference/Conference';
import SyncMeeting from '../Pages/Home/MeetingDetails/SyncMeeting/SyncMeeting';
import AddEditUser from '../Pages/Admin/UserManagement/AddEditUser/AddEditUser';
// import Chat from '../Pages/Home/Chat/Chat';
import { AppProvider } from '../Shared/Utils/AppContext';
import AttendeeDetails from '../Pages/Home/AttendeeDetails/AttendeeDetails';
import Survey from '../Pages/Home/Survey/Survey';
import SurveyDetails from '../Pages/Home/Survey/SurveyDetails';
import KOL from '../Pages/Home/KOL.js/KOL';
import MeetingFollowUp from '../Pages/Home/Engagement/FollowUp/MeetingFollowUp';

function App() {


  return (
    <AppProvider>
      <Loader />
      <ToasterAlert />
      <ProvideAuth>
        <div className="App" data-test="app-container">
          <HashRouter>
            <Routes>
              <Route exact path="/" element={<Navigate to="/home" />} />
              <Route exact path="/login" element={<SecureLogin />} />
              <Route exact path="/logout" element={<SecureLogout />} />
              <Route exact path="/icons" element={<IconComponent />} />
              <Route exact path='/home' element={<PrivateRoute path='/home' />}>
                <Route exact path='/home' element={<><Header /><Home /></>
                } />
                <Route path="/home/meetingDetails/:id" element={<><Header /><MeetingDetails /></>} />
                <Route path="/home/meetingDetails/:id/engagement/:meetingID" element={<><Header /><Engagement /></>} />
                <Route path="/home/meetingDetails/engagement/:addNew" element={<><Header /><Engagement /></>} />
                {/* <Route path="/home/conference/:addNew" element={<><Header /><Conference /></>} /> */}
                <Route path="/home/surveyList" element={<><Header /><Survey /></>} />
                <Route path="/home/followUp" element={<><Header /><MeetingFollowUp /></>} />
                <Route path="/home/surveyList/surveyDetails/:survey_id" element={<><Header /><SurveyDetails /></>} />
                <Route path="/home/conference/:conference_id" element={<><Header /><Conference /></>} />
                <Route path="/home/profiles/:addNew" element={<><Header /><AttendeeDetails /></>} />
                <Route path="/home/profiles" element={<><Header /><KOL /></>} />
                <Route path="/home/profiles/profileDetails/:id" element={<><Header /><KOLProfileDetails /></>} />
                <Route path="/home/attendeeDetails/:attendee_id" element={<><Header /><AttendeeDetails /></>} />
                {/* <Route path="/home/syncMeeting" element={<><Header /><SyncMeeting /></>} /> */}
                {/* <Route path="/home/chat" element={<><Header /><Chat /></>} /> */}
              </Route>
              <Route exact path='/admin' >
                {/* <Route exact path='/admin' element={<><Header /><AdminMain /></>} /> */}
                <Route exact path='/admin/settings' element={<><Header /><AdminMain /></>} />
                <Route path="/admin/user/:userId" element={<><Header /><AddEditUser/></>}/>
                <Route path="/admin/user/add" element={<><Header /><AddEditUser/></>}/>
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </HashRouter>
        </div>
      </ProvideAuth>
    </AppProvider>
  );
}

export default App;
