import React, { useEffect, useRef, useState } from 'react'
import './AttendeeDetails.scss';
import BreadcrumbList from '../../../Shared/Components/Breadcrumb/Breadcrumb';
import { Row, Col, Card, Table, Typography, Input, Button, Space, Upload, Avatar, Badge,Tooltip,List } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { ServiceUtils } from '../../../Shared/Utils/ServiceUtils';
import { alertService } from '../../../Shared/Utils/ToasterUtils';
import { EditFilled, UploadOutlined, UserAddOutlined } from '@ant-design/icons';
import { getDecryptSession } from '../../../Shared/Utils/CommonUtils';

import { Tag } from 'antd';
export default function AttendeeDetails({ ...props }) {

  
const columns = [
  {
    title: 'Meetings Info',
    dataIndex: 'address_data',
    key: 'address_data',
    render(text) {
      return {
        props: {
          style: { background: text?.color ,cursor: "pointer"}
        },
        children: 
          <Tooltip style={{width:"100%"}} title={text?.meeting_start_ts +" - " + text?.meeting_end_ts +" - "+ text?.meeting_venue}>
              <h5 onClick={() => onSelectMeeting(text.route)}>{text.meeting_description}</h5>
          </Tooltip>
      };
    }
  }
];
const data = [
  {
    address_data:
    {
      "meeting_id": 22,
      "meeting_description": "Arcutis Meeting: Mark Lebwohl MD (Hilton Bayfront Rm: Aqua 307; 8-8:50am PST *HARD STOP)\n; Location: Hilton San Diego Bayfront; Room Aqua 307",
      "meeting_start_ts": "March 08, 2024 08:00 AM",
      "meeting_end_ts": "March 08, 2024 08:50 AM",
      "meeting_venue": "Hilton San Diego Bayfront; Room Aqua 307",
      "route": "/home/meetingDetails/1/engagement/22",
      "color":'#C6E0B4',
  }
  },
  {
    address_data: {
      address:'New York No. 1 Lake Park dnknknkw',
      color:'#FFE699',
      tooltip:'hello 24/06/2024 New Delhi India'
    }
  },
  {
    address_data: {
      address:'New York No. 1 Lake Park dnkwknfcihifhwinkwncklwnmclwjmojojowhidhiwhdnkqwnxkwnciwhfuwhdiudguqfydhqwnclwkmcdwlmdowjdow',
      color:'#C6E0B4',
      tooltip:'hello 24/06/2024 New Delhi India'
    }
  },
  {
    address_data: {
      address:'New York No. 1 Lake Park bkfbkbmnw dm wmnckneknvjeje',
      color:'#BDD7EE',
      tooltip:'hello 24/06/2024 New Delhi India'
    }
  },
  {
    address_data: {
      address:'New York No. 1 Lake Park dnknknkw',
      color:'#AEAAAA',
      tooltip:'hello 24/06/2024 New Delhi India'
    }
  },
  {
    address_data: {
      address:'New York No. 1 Lake Park dnkwknfcihifhwinkwncklwnmclwjmojojowhidhiwhdnkqwnxkwnciwhfuwhdiudguqfydhqwnclwkmcdwlmdowjdow',
      color:'#FF474C',
      tooltip:'hello 24/06/2024 New Delhi India'
    }
  },
  {
    address_data: {
      address:'New York No. 1 Lake Park dnkwknfcihifhwinkwncklwnmclwjmojojowhidhiwhdnkqwnxkwnciwhfuwhdiudguqfydhqwnclwkmcdwlmdowjdow',
      color:'#9966FF',
      tooltip:'hello 24/06/2024 New Delhi India'
    }
  },
];

const data1 = [
  {
    title: 'Ant Design Title 1',
  },
  {
    title: 'Ant Design Title 2',
  },
  {
    title: 'Ant Design Title 3',
  },
  {
    title: 'Ant Design Title 4',
  },
];

  const [profileDetails, setProfileDetails] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [isEditEnabled, setIsEditEnabled] = useState(true);
  const [imageFile, setImageFile] = useState('');
  const [uploadProfileImage, setProfileImage] = useState();
  const [userDetails, setUserDetails] = useState({});
  let { addNew } = useParams();
  const { id } = useParams();
  const { attendee_id } = useParams();
  const navigate = useNavigate();
  const { Title, Text } = Typography;
  const fileInputRef = useRef(null);


  useEffect(() => {
    const session = localStorage.getItem("arcutis-user");
    const userDetails = getDecryptSession(session);
    setUserDetails(userDetails);
    if (id !== undefined || addNew === 'addProfile') {
      fetchProfileDetails();
      addNew === 'addProfile' ? setIsEditEnabled(true) : setIsEditEnabled(false);
    } else {
      addNew === 'addAttendee' ? setIsEditEnabled(true) : setIsEditEnabled(false);
      fetchAttendeeDetails();
    }
  }, [])


  const fetchProfileDetails = () => {
    let payload = {
      profileId: id
    }
    ServiceUtils.postRequest('profileDetails', payload).then(
      (response) => {
        if (response?.status === 200 && response?.data?.status) {
          setProfileDetails(response.data?.data);
          setProfileImage(response.data?.profile_picture);
        } 
        else if(response?.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response?.data?.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching user role.')
        console.log(error)
      }
    );
  };

  const fetchAttendeeDetails = () => {
    let payload = {
      attendee_id: attendee_id
    }
    ServiceUtils.postRequest('attendeesInfo', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          setProfileDetails(response.data.data);
          setProfileImage(response.data.profile_picture);
        } 
        else if(response.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response.data.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching user role.')
        console.log(error)
      }
    );
  };

  const handleInputChange = (key, value, profile) => {
    profileDetails.map(el => {
      if (el.label === profile.label) {
        el.data.map(obj => {
          if (obj.key === key) {
            obj.value = value
          }
        })
      }
    })
    setProfileDetails(profileDetails)
  };
  const onSelectMeeting = (data) => {
    navigate(data);
  }
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const fsize = event.target.files[0].size;
    const fileSizeBK = Math.round((fsize / 1024));
    if(fileSizeBK<=512){
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setProfileImage(reader.result);
        setImageFile(event.target.files[0])
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
    else{
      alertService.error("Please select lower resolution images (less than 500KB)")
    }
  }

  const onEditProfile = () => {
    setIsEditEnabled(true)
  }

  const onSaveProfile = () => {
    let validation = false;
    const formData = new FormData();
    profileDetails.map(el => {
      if (el.label === 'Demographics' || el.label === 'Arcutis Engagement') {
        el.data.map(data => {
          if(el.label === 'Demographics'){
            if(data.update_key ==="first_name" ||data.update_key === "last_name" || data.update_key === "email_address" || data.update_key === 'user_email_id'){
              if (data.value === '') {
                validation = true;
              }
              if (data.update_key === 'user_email_id' || data.update_key === 'email_address') {
                var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if (!data.value.match(validRegex)) {
                  alertService.error('Please Enter the Valid Email');
                  validation = true;
                }
              }
            }
          }
          formData.append(data.update_key, data.value);
        })
      }
    })

    if (validation) {
      alertService.error('Please Fill All The Fields');
      return;
    }
    formData.append('profile_picture', imageFile);
    (id !== undefined || addNew === 'addProfile') ? formData.append('type', id === undefined ? 'add' : 'edit') : formData.append('type', attendee_id === undefined ? 'add' : 'edit')
    if (id !== undefined || addNew === 'addProfile') {
      formData.append('profile_id', id === undefined ? 'null' : id.toString())
    } else {
      formData.append('attendee_id', attendee_id === undefined ? 'null' : attendee_id.toString());
    }
    ServiceUtils.postRequest((id !== undefined || addNew === 'addProfile') ? 'updatePeople' : 'updateArcutisUser', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          onCancelProfile()
        } 
        else if(response.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response.data.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching data')
        console.log(error)
      }
    );
  };

  const onCancelProfile = () => {
    navigate('/home');
    localStorage.setItem('history', '/home');
  }

  const onEditClick = () => {
    fileInputRef.current.click();
  };

  const checkFUnction = (event) => {
    // console.log(event);
  }


  return (
    <>
      <div className="profile-root" data-test="profile-container">
          <Card style={{ width: '100%', height: 'fit-content' }} bodyStyle={{ padding: '10px' }}>
            {id !== undefined || addNew === 'addProfile' ?
              <BreadcrumbList active={[{ label: 'Profiles', link: '/home/profiles' }, { label: ' ', link: '' }, { label: 'Profile Details', link: '' }]} />
              : <BreadcrumbList active={[{ label: 'Attendee Details', link: '' }]} />
            }

          </Card>
        <div className="list-container">
          <div className="list-container">
            <Card className='profile'>
              {
                !isEditEnabled && userDetails.admin_access &&
                <Button className="edit-meet-button" style={{ position: 'absolute', top: '10px', right: '30px' }} type="primary" onClick={() => onEditProfile()}>Edit</Button>
              }
              {
                isEditEnabled &&
                <div>
                  <Button className="edit-meet-button" style={{ position: 'absolute', top: '10px', right: '30px' }} type="primary" onClick={() => onSaveProfile()}>Save</Button>
                  <Button style={{ position: 'absolute', top: '10px', right: '99px' }} onClick={() => onCancelProfile()}>Cancel</Button>
                </div>

              }
              <div style={{ margin: '10px 0px', display: 'grid', justifyContent: 'center' ,padding: '15px'}}>
                <div className='text-center'>
                  <h3>Profile Picture
                    {isEditEnabled &&
                      <div style={{ display: 'contents' }}>
                        <input ref={fileInputRef}
                          type="file"
                          accept="image/jpg, image/jpeg, image/png,image/webp"
                          style={{ display: 'none' }} onChange={handleFileInputChange} />
                        <span onClick={onEditClick} className='edit-icon pointer'><EditFilled /></span>
                      </div>}
                  </h3>
                  <Space wrap size={16}>
                    <Avatar size={120} src={uploadProfileImage} />
                  </Space>
                </div>
              </div>
              {profileDetails && profileDetails.length > 0 && profileDetails.map(profile =>
                <Card style={{ marginBottom: '2%',backgroundColor: "#f6f6f6" }}>
                  <div className='profile-form-root'>
                    {profile.label}
                  </div>
                  {profile.label === 'Demographics' || profile.label === 'Arcutis Engagement' ?
                    <Row>
                      {profile.data && profile.data.map(el =>
                        <Col className='form-group ' xs={{ flex: '100%' }}
                          sm={{ flex: '100%' }}
                          md={{ flex: '50%' }}
                          lg={{ flex: '25%' }}
                          xl={{ flex: '25%' }}>
                          <div style={{ display: 'grid' }}>
                            <label class="form-label text-ellipsis-one-line">{el.key}</label>
                            {!isEditEnabled ?
                              <label class="text-only text-ellipsis-one-line">{el.value}</label>
                              : <Input style={{ width: '90%' }} key={el.key} defaultValue={el.value} onChange={(e) => handleInputChange(el.key, e.target.value, profile)} />
                            }
                          </div>
                        </Col>
                      )}
                    </Row>
                    : profile.label === 'AAD Engagement Meetings' ?
                      <div className="table-kol">
                        {/* <div style={{ display: 'grid', justifyContent: 'left' }}>
                          {profile.data && profile.data.length > 0 && profile.data.map((el, index) =>
                            <div style={{ display: 'flex', }}>
                              <span style={{ marginTop: '5px' }}> {index + 1})</span><Title level={5} className='pointer' title={el.meeting_start_ts + ',' + el.meeting_end_ts + ',' + el.meeting_venue} style={{ margin: '5px', fontWeight: '400', textAlign: 'left' }}
                                onClick={() => onSelectMeeting(el.route)}>{el.meeting_description}</Title>
                            </div>
                          )}
                        </div> */}
                        <Table 
                          style={{marginTop:'10px'}}
                          columns={columns}
                          dataSource={profile.data}
                          rowClassName={checkFUnction} 
                          onRow={(record, rowIndex) => {
                            return {
                              onClick: (event) => {console.log(record,event)}, 
                            };
                          }}
                          pagination={false}
                        />
                      </div>
                      : profile.label === 'Engagement Timeline' &&
                      <div><Title level={4} className="text-center">Under-Construction</Title></div>
                  }
                </Card>
              )}
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}
