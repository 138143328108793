import React, { useEffect, useRef, useState } from 'react'
import './KOLProfileDetails.scss';
import BreadcrumbList from '../../../Shared/Components/Breadcrumb/Breadcrumb';
import { Row, Col, Card, Table, Typography, Input, Button, Space, Upload, Avatar, Badge,Tooltip,List, Collapse, Divider, Layout, Menu } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { ServiceUtils } from '../../../Shared/Utils/ServiceUtils';
import { alertService } from '../../../Shared/Utils/ToasterUtils';
import { getDecryptSession } from '../../../Shared/Utils/CommonUtils';
import {
  EditFilled,
  CaretRightOutlined,
  MenuOutlined,
  FilterOutlined,
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  IdcardOutlined,
  SolutionOutlined,
  LineChartOutlined,
  ScheduleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Tag } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import ArcutisEngagements from './ProfileSections/ArcutisEngagements/ArcutisEngagements';
import ActivityTimeLine from './ProfileSections/ActivityTimeLine/ActivityTimeLine';
import PrescriptionBehaviour from './ProfileSections/PrescriptionBehaviour/PrescriptionBehaviour';
import TLLScoreCard from './ProfileSections/TLLScoreCard/TLLScoreCard';
import ActivityDetails from './ProfileSections/ActivityDetails/ActivityDetails';
import TouchPoints from './ProfileSections/TouchPoints/TouchPoints';
import TeamTargetFlags from './ProfileSections/TeamTargetFlags/TeamTargetFlags';
import CongressMeetings from './ProfileSections/CongressMeetings/CongressMeetings';
export default function KOLProfileDetails({ ...props }) {

  const hierarchy=[{ label: 'Profiles', link: '/home/profiles' }, { label: ' ', link: '' }, { label: 'Profile Details', link: '' }];


  
const columns = [
  {
    title: 'Meetings Info',
    dataIndex: 'address_data',
    key: 'address_data',
    render(text) {
      return {
        props: {
          style: { background: text?.color ,cursor: "pointer"}
        },
        children: 
          <Tooltip style={{width:"100%"}} title={text?.meeting_start_ts +" - " + text?.meeting_end_ts +" - "+ text?.meeting_venue}>
              <h5 onClick={() => onSelectMeeting(text.route)}>{text.meeting_description}</h5>
          </Tooltip>
      };
    }
  }
];

const items =  [
  { key: '1', icon: <UserOutlined />, label: 'Demographics' },
  { key: '2', icon:<ScheduleOutlined />, label: 'Arcutis Engagements' },
  { key: '3', icon: <ContainerOutlined />, label: 'Activity Deals' },
  { key: '4', icon: <LineChartOutlined />, label: 'Activity Timeline' },
  { key: '5', icon: <SolutionOutlined />, label: 'Prescription Behaviour' },
  { key: '6', icon: <IdcardOutlined />, label: 'TLL Scorecard' },
  { key: '7', icon: <ScheduleOutlined />, label: 'Congress Meetings' },
]

const iconMapping={
  'userIcon':<UserOutlined />,
  'arcutisEngagementIcon':<ScheduleOutlined />,
  'activityIcon':<ContainerOutlined />,
  'activityTimelineIcon':<LineChartOutlined />,
  'prescriptionIcon':<SolutionOutlined />,
  'tllScorecardIcon':<IdcardOutlined />,
  'congressMeetingIcon':<ContainerOutlined />,
}
  const [profileDetails, setProfileDetails] = useState([]);
  const [demographicValues, setDemographicValues] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [isEditEnabled, setIsEditEnabled] = useState(true);
  const [imageFile, setImageFile] = useState('');
  const [uploadProfileImage, setProfileImage] = useState('');
  const [userDetails, setUserDetails] = useState({});
  let { addNew } = useParams();
  const { id } = useParams();
  const { attendee_id } = useParams();
  const navigate = useNavigate();
  const { Title, Text } = Typography;
  const fileInputRef = useRef(null);
  const [showFilter, setShowFilter] = useState(false);
  const [menuItems,setMenuItems] = useState([]);
  const [menu,setMenu]=useState('1')

  useEffect(() => {
    const session = localStorage.getItem("arcutis-user");
    const userDetails = getDecryptSession(session);
    setUserDetails(userDetails);
    if (id !== undefined || addNew === 'addProfile') {
      // fetchProfileDetails();
      addNew === 'addProfile' ? setIsEditEnabled(true) : setIsEditEnabled(false);
    } else {
      addNew === 'addAttendee' ? setIsEditEnabled(true) : setIsEditEnabled(false);
      fetchAttendeeDetails();
    }
    fetchSideBarDetails();
  }, [])

  useEffect(()=>{
    if(Number(menu) === 1) fetchProfileDetails();
  },[menu])


  const fetchProfileDetails = () => {
    let payload = {
      profileId: id,
      sectionId:Number(menu)
    }
    ServiceUtils.postRequest('kolProfile', payload).then(
      (response) => {
        if (response?.status === 200) {
          setProfileDetails(response.data?.data);
          let data = JSON.parse(JSON.stringify(response.data?.data));
          let result = {};
          data?.demographics?.values.map(demography => {
            result[demography?.update_key] = demography.value
          })
          setDemographicValues(result);
          if(response.data?.data?.profile_picture) setProfileImage(response.data?.data?.profile_picture);
        } 
        else if(response?.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response?.data?.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching user role.')
        console.log(error)
      }
    );
  };


  const fetchAttendeeDetails = () => {
    let payload = {
      attendee_id: attendee_id
    }
    ServiceUtils.postRequest('attendeesInfo', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          setProfileDetails(response.data.data);
          if(response.data?.profile_picture) setProfileImage(response.data.profile_picture);
        } 
        else if(response.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response.data.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching user role.')
        console.log(error)
      }
    );
  };

  const fetchSideBarDetails = () => {
    ServiceUtils.getRequest('kolSections').then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          let tempResponse = response?.data?.data?.map((sideBarDetail)=>{
            return({...sideBarDetail,icon:iconMapping[sideBarDetail?.icon],key:String(sideBarDetail?.key)})
          })
          setMenuItems([...tempResponse]);
        } 
        else if(response.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response.data.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching user role.')
        console.log(error)
      }
    );
  };

  const handleInputChange = (key, value, profile) => {
    profileDetails?.values?.map(el => {
      if (el.label === profile.label) {
        el.data.map(obj => {
          if (obj.key === key) {
            obj.value = value
          }
        })
      }
    })
    setProfileDetails(profileDetails)
  };

  const onSelectMeeting = (data) => {
    navigate(data);
  }
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const fsize = event.target.files[0].size;
    const fileSizeBK = Math.round((fsize / 1024));
    if(fileSizeBK<=512){
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setProfileImage(reader.result);
        setImageFile(event.target.files[0])
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
    else{
      alertService.error("Please select lower resolution images (less than 500KB)")
    }
  }

  const onEditProfile = () => {
    setIsEditEnabled(true)
  }

  const onSaveProfile = () => {
    console.log(demographicValues,"demography on save")
    let validation = false;
    const formData = new FormData();
    if (profileDetails?.demographics?.label === 'Demographics') {
      profileDetails?.demographics?.values?.map(data => {
        if(data.update_key ==="first_name" ||data.update_key === "last_name" || data.update_key === "email_address" || data.update_key === 'user_email_id'){
          if (data.value === '') {
            validation = true;
            // alertService.error('Please Fill All The Fields');
          }
        } else 
        if (data.update_key === 'user_email_id' || data.update_key === 'email_address') {
          var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          if (!data.value.match(validRegex)) {
            alertService.error('Please Enter the Valid Email');
            validation = true;
          }
        }
      })
    }
    // profileDetails?.values?.map(el => {
    //   if (profileDetails?.demographics?.label === 'Demographics' || el.label === 'Arcutis Engagement') {
    //     el.data.map(data => {
    //       if(el.label === 'Demographics'){
    //         if(data.update_key ==="first_name" ||data.update_key === "last_name" || data.update_key === "email_address" || data.update_key === 'user_email_id'){
    //           if (data.value === '') {
    //             validation = true;
    //             // alertService.error('Please Fill All The Fields');
    //           }
    //           if (data.update_key === 'user_email_id' || data.update_key === 'email_address') {
    //             var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    //             if (!data.value.match(validRegex)) {
    //               alertService.error('Please Enter the Valid Email');
    //               validation = true;
    //             }
    //           }
    //         }
    //       }
    //       formData.append(data.update_key, data.value);
    //     })
    //   }
    // })

    if (validation) {
      alertService.error('Please Fill All The Fields');
      return;
    }
    formData.append('profile_picture', imageFile);
    
    formData.append('demography',JSON.stringify(demographicValues));
    formData.append('type','edit');
    formData.append('profile_id', id === undefined ? 'null' : id.toString())
    // if (id !== undefined || addNew === 'addProfile') {
    //   formData.append('profile_id', id === undefined ? 'null' : id.toString())
    // } else {
    //   formData.append('attendee_id', attendee_id === undefined ? 'null' : attendee_id.toString());
    // }

    ServiceUtils.postRequest('updateHcpProfile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          onCancelProfile();
          alertService.success(response?.data?.message);
        } 
        else if(response.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response.data.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching data')
        console.log(error)
      }
    );
  };

  const onCancelProfile = () => {
    setIsEditEnabled(false);
  }

  const onEditClick = () => {
    fileInputRef.current.click();
  };

  const checkFUnction = (event) => {
    // console.log(event);
  }


  return (
    <>
      <div className="profile-root" data-test="profile-container">
      {/* <Card
        style={{ width: "100%", height: "fit-content" }}
        bodyStyle={{ padding: "10px" }}
      >
        <BreadcrumbList active={hierarchy} />
      </Card> */}
      <Layout>
         <Content>
          <Card style={{ width: '100%', height: 'fit-content' }} bodyStyle={{ padding: '10px' }}>
            {id == undefined || addNew !== 'addProfile' ?
              <BreadcrumbList active={hierarchy} />
              : <BreadcrumbList active={[{ label: 'Attendee Details', link: '' }]} />
            }

          </Card>
        <div className="list-container">
          <div className="list-container">
            <Card className='profileCard' style={{ padding: 0 }}>
              <div style={{display:"flex",width:'100%'}}>
              <div style={{ display: 'flex', height: '85vh' ,width:'100%'}}>
              <div style={{ width: showFilter ? '80px' : '250px', transition: 'width 0.3s' ,borderRight:'1px solid #EAEAEA'}}>
                <Button style={{margin:'10px 15px',border:'none'}} onClick={() => setShowFilter(!showFilter)}>
                  <MenuOutlined />
                </Button>
                <Menu
                  defaultSelectedKeys={['1']}
                  mode="inline"
                  inlineCollapsed={showFilter}
                  items={menuItems}
                  className="custom-menu"
                  onClick={(menuValue)=>setMenu(menuValue?.key)}
                />
              </div>
              {menu === '1' && <div style={{ flex: 1, padding: '16px' }}>
              <>
              <p className="headerLabel">{profileDetails?.demographics?.label} {profileDetails?.user_name && `- ${profileDetails?.user_name}`}</p>
              {
                !isEditEnabled && userDetails?.role_access?.arcutisAttendee?.edit &&
                <Button className="edit-meet-button" style={{ position: 'absolute', top: '10px', right: '30px' }} type="primary" onClick={() => onEditProfile()}>Edit</Button>
              }
              {
                isEditEnabled &&
                <div>
                  <Button className="edit-meet-button" style={{ position: 'absolute', top: '10px', right: '30px' }} type="primary" onClick={() => onSaveProfile()}>Save</Button>
                  <Button style={{ position: 'absolute', top: '10px', right: '99px' }} onClick={() => onCancelProfile()}>Cancel</Button>
                </div>

              }
              </>
              <Card style={{padding:'20px',height: 'calc(100% - 50px)',overflow: 'auto'}}>
              <div>
              <div style={{display: 'grid', justifyContent: 'center' ,paddingBottom: '4rem'}}>
                <div className='text-center'>
                  <h3>Profile Picture
                    {isEditEnabled &&
                      <div style={{ display: 'contents' }}>
                        <input ref={fileInputRef}
                          type="file"
                          accept="image/jpg, image/jpeg, image/png,image/webp"
                          style={{ display: 'none' }} onChange={handleFileInputChange} />
                        <span onClick={onEditClick} className='edit-icon pointer'><EditFilled /></span>
                      </div>}
                  </h3>
                  <Space wrap size={16}>
                    {(uploadProfileImage !=='' && uploadProfileImage !== 'None') ? <Avatar size={180} src={uploadProfileImage} />:
                    <Avatar
                    style={{
                      verticalAlign: "middle",
                    }}
                    size={180}
                  >
                    <UserOutlined style={{fontSize:'120px'}} />
                  </Avatar>}
                  </Space>
                </div>
              </div>
              <Row gutter={[16, 16]}>
                {profileDetails?.demographics?.values?.length > 0 &&
                  profileDetails?.demographics?.values?.map((profile, index) => {
                    return (
                      <Col  xs={24} 
                      sm={12} 
                      md={8} 
                      lg={6} 
                      key={index}>
                        {profileDetails?.demographics.label === 'Demographics' && (
                          <div style={{ display: 'grid' }}>
                            <label className="form-label text-ellipsis-one-line">{profile?.key}</label>
                            {!isEditEnabled ? (
                              profile?.url?
                              <label onClick={()=>window.open(profile?.url,'blank')} className="text-only text-ellipsis-one-line link">
                              {profile?.value || '-'}
                            </label>
                              :
                              <label className="text-only text-ellipsis-one-line">
                                {profile?.value || '-'}
                              </label>
                            ) : (
                              <Input
                                style={{ width: '90%' }}
                                key={profile.key}
                                defaultValue={profile.value}
                                onChange={(e) =>
                                  setDemographicValues({
                                    ...demographicValues,
                                    [profile?.update_key] : e.target.value
                                  })
                                  // handleInputChange(profile.key, e.target.value, profile)
                                }
                              />
                            )}
                          </div>
                        )}
                      </Col>
                    );
                  })}
              </Row>

              </div>
              </Card>
              </div>}
              {menu === '2' && <CongressMeetings menuId={menu}/>}
              {menu === '3' && <TouchPoints menuId={menu}/>}
              {menu === '4' && <TeamTargetFlags menuId={menu}/>}
              {menu === '5' && <ArcutisEngagements menuId={menu}/>}
              {menu === '6' && <ActivityDetails menuId={menu}/>}
              {menu === '7' && <ActivityTimeLine menuId={menu}/>}
              {menu === '8' && <PrescriptionBehaviour menuId={menu}/>}
              {menu === '9' && <TLLScoreCard menuId={menu}/>}
              </div>
            </div>
             
            </Card>
          </div>
        </div>
            </Content>
            </Layout>
      </div>
    </>
  )
}
