import './FollowUpCards.scss';
import useWindowSize from '../../../../Shared/Utils/windowResizeUtils'
import {
    Row,
    Col,
    Typography,
    Divider,
    Card,
    Select,
    Tooltip,
    theme,
    Space,
    Popconfirm,
    Dropdown,
    Input,
    Button
  } from "antd";
  import { Option } from "antd/es/mentions";
  import classNames from "classnames";
  import { useSharedState } from "../../../../Shared/Services/WindowSizeService";
  import { MoreOutlined, CheckCircleFilled, MailFilled, EnterOutlined, PlusOutlined } from "@ant-design/icons";
  import { ExportOutlined, SearchOutlined,LaptopOutlined, NotificationOutlined, UserOutlined,ExclamationCircleFilled,CloseCircleOutlined, CaretRightOutlined, FilterOutlined, CloseOutlined } from "@ant-design/icons";

const FollowUpCards = ({dataSource,columns,actionDetails,userDetails,onAction,followUpRequired,tableDataRef,...props}) => {
    const windowSize$ = useSharedState();
    const { width } = useWindowSize();
    const isIpad = width <= 820;
    const isMobile = width <= 430;
    const { useToken } = theme;
    const { token } = useToken();
    const { TextArea } = Input;
    const contentStyle = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
      };
    
  const items = [
    {
      label: <Space>Edit</Space>,
      key: "0",
      evtype: "edit",
    },
    {
      label: <Space>Delete</Space>,
      key: "1",
      evtype: "delete",
    },
  ];

  console.log(dataSource,"on cards")

    const handleDropdownChange = (value, key, label,index) => {
        onAction({
            'type': 'dropdownChange',
            'value':value,
            'key':key,
            'label':label,
            'record':null,
            'index':index
        });
      };

      const check = (rec) => {
        return !rec?.change
      }

    const submitData = (data) => {
        onAction({
            'type': 'submitData',
            'bodyContent': data
        });
    }

    const handleDelete = (key,record) => {
        onAction({
            'type': 'handleDelete',
            'key': key,
            'record': record
        });
    }

    
  const checkAllFieldsFilled = (data) => {
    let retFlag = false;
    if (data?.action_assigned_to && data?.action_on && data?.action_status) {
      retFlag = true;
    }
    return retFlag;
  }

  const traceCurrentMail = (index) => {
    onAction({
        'type': 'traceCurrentMail',
        'index': index
    });
  }

    return (
        <>
            <div style={{ padding: '5px', marginBottom: '20px' }} className="follow-up-mobile-card">
                <Row gutter={[10, 10]}>
                    {dataSource?.map((item, index) => (

                        <Col xs={24} sm={24} md={24} key={item.key}>
                            <Card
                                title={
                                    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                        <div>
                                            <a
                                                style={{ color: '#235D9F', fontWeight: '400', padding: '5px 0px' }}

                                            >
                                                <span>Follow Up</span> 
                                                {item?.sequence && <span style={{marginLeft: '2px'}}>({item?.sequence})</span>}
                                            </a>
                                        </div>
                                        <div className={classNames("", {
                                            "width-action-small": windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md",
                                        })} style={{ display: "flex",  padding: '5px 0px' }}>
                                            <div>
                                                <Button onClick={() => {
                                                    submitData(item)
                                                }} className="edit-meet-button" disabled={check(item) || followUpRequired} type="primary">
                                                    Submit
                                                </Button>
                                            </div>
                                            {!check(item) && !followUpRequired &&
                                                <div className="dropdown-follow-up">
                                                    <Dropdown
                                                        menu={{
                                                            items,
                                                            selectable: true,
                                                        }}
                                                        dropdownRender={(menu) => (
                                                            <div style={contentStyle}>
                                                                {item?.created && (
                                                                    <>
                                                                        <Space
                                                                            className="pointer"
                                                                            style={{
                                                                                padding: 10,
                                                                            }}
                                                                            onClick={() => {
                                                                                traceCurrentMail(index);
                                                                            }}
                                                                        >
                                                                            Trace mail
                                                                        </Space>
                                                                        <br />
                                                                        <Divider
                                                                            style={{
                                                                                margin: 0,
                                                                            }}
                                                                        />
                                                                    </>
                                                                )}
                                                                {
                                                                    checkAllFieldsFilled(item) && (
                                                                        <>
                                                                            <Space
                                                                                className="pointer"
                                                                                style={{
                                                                                    padding: 10,
                                                                                }}
                                                                                onClick={() => {
                                                                                    handleDropdownChange(2, "mail_type", "Compose Mail", index)
                                                                                }}
                                                                            >
                                                                                Compose mail
                                                                            </Space>
                                                                            <br />
                                                                            <Divider
                                                                                style={{
                                                                                    margin: 0,
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )
                                                                }
                                                                <Space
                                                                    className="pointer"
                                                                    style={{
                                                                        padding: 10,
                                                                    }}
                                                                >
                                                                    <Popconfirm
                                                                        title="Sure to delete?"
                                                                        onConfirm={() => handleDelete(item.key, item)}
                                                                    >
                                                                        Delete
                                                                    </Popconfirm>
                                                                </Space>

                                                            </div>
                                                        )}
                                                    >
                                                        <Typography.Link >
                                                            <Space>
                                                                <MoreOutlined style={{ fontSize: '20px' }} />
                                                            </Space>
                                                        </Typography.Link>
                                                    </Dropdown>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                style={{ border: '0.5px solid #EAEAEA' }}
                                styles={{
                                    body: { padding: '0 10px 10px 10px' },
                                    head: { borderBottom: '1px solid #EAEAEA' },
                                }}
                                bordered={false}
                            >
                                <Divider style={{ margin: '0 0 5px 0', borderWidth: '2px' }} />
                                <Row gutter={24} style={{ margin: '10px'}}>
                                    <Col xs={8} sm={8} md={8}>
                                        <p style={{ margin: 0, color: '#5D6063',fontWeight: 'bolder' }}>Notes&nbsp; </p>
                                    </Col>
                                    <Col xs={16} sm={16} md={16}>
                                        <div className="notes">
                                            <TextArea
                                                disabled={followUpRequired}
                                                rows={3}
                                                defaultValue={item?.followup_notes ? item?.followup_notes : undefined}
                                                value={item?.followup_notes ? item?.followup_notes : undefined}
                                                style={{ width: "100%" }}
                                                maxLength={500}
                                                placeholder="Follow Up Notes"
                                                autoComplete='off'
                                                autoSize={{ minRows: 2, maxRows: 6 }}
                                                onChange={
                                                    (e) =>
                                                        handleDropdownChange(e.target.value, "followup_notes", 
                                                            '', index)
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={24} style={{ margin: '10px'}}>
                                    <Col xs={8} sm={8} md={8}>
                                        <p style={{ margin: 0, color: '#5D6063',fontWeight: 'bolder' }}>Action&nbsp; </p>
                                    </Col>
                                    <Col xs={16} sm={16} md={16}>
                                        <div className="action-on">
                                            <Select
                                                placeholder="Select Action on"
                                                defaultValue={item?.action_on ? item?.action_on : undefined}
                                                value={item?.action_on ? item?.action_on : undefined}
                                                style={{ width: "100%" }}
                                                disabled={!userDetails?.role_access?.home?.conferences?.edit || followUpRequired}
                                                maxTagCount="responsive"
                                                onChange={
                                                    (value, label) =>
                                                        handleDropdownChange(value, "action_on", label, index)
                                                }
                                            >
                                                {actionDetails &&
                                                    actionDetails.action_on.length > 0 &&
                                                    actionDetails.action_on.map((el) => (
                                                        <Option value={el.value}>{el.label}</Option>
                                                    ))}
                                            </Select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={24} style={{ margin: '10px'}}>
                                    <Col xs={8} sm={8} md={8}>
                                        <p style={{ margin: 0, color: '#5D6063',fontWeight: 'bolder' }}>Assigned To&nbsp; </p>
                                    </Col>
                                    <Col xs={16} sm={16} md={16}>
                                        <Select
                                            placeholder="Select Action assigned to"
                                            defaultValue={
                                                item?.action_assigned_to
                                                    ? item?.action_assigned_to
                                                    : undefined
                                            }
                                            disabled={!userDetails?.role_access?.home?.conferences?.edit || followUpRequired}
                                            value={
                                                item?.action_assigned_to
                                                    ? item?.action_assigned_to
                                                    : undefined
                                            }
                                            style={{ width: "100%" }}
                                            maxTagCount="responsive"
                                            onChange={(value, label) =>
                                                handleDropdownChange(
                                                    value,
                                                    "action_assigned_to",
                                                    label,
                                                    index
                                                )
                                            }
                                        >
                                            {actionDetails &&
                                                actionDetails?.action_assigned_to?.length > 0 &&
                                                actionDetails?.action_assigned_to.map((el) => (
                                                    <Option value={el.value}>{el.label}</Option>
                                                ))}
                                        </Select>
                                    </Col>
                                </Row>
                                <Row gutter={24} style={{ margin: '10px',}}>
                                    <Col xs={8} sm={8} md={8}>
                                        <p style={{ margin: 0, color: '#5D6063',fontWeight: 'bolder' }}>Action Status&nbsp; </p>
                                    </Col>
                                    <Col xs={16} sm={16} md={16}>
                                        <Select
                                            placeholder="Select Status of Follow-up"
                                            disabled={!userDetails?.role_access?.home?.conferences?.edit || followUpRequired}
                                            defaultValue={
                                                item?.action_status ? item?.action_status : undefined
                                            }
                                            value={item?.action_status ? item?.action_status : undefined}
                                            style={{ width: "100%" }}
                                            onChange={(value, label) =>
                                                handleDropdownChange(value, "action_status", label, index)
                                            }
                                        >
                                            {actionDetails &&
                                                actionDetails.action_status.length > 0 &&
                                                actionDetails.action_status.map((el) => (
                                                    <Option value={el.value}>{el.label}</Option>
                                                ))}
                                        </Select>
                                    </Col>
                                </Row>
                                <Row gutter={24} style={{ margin: '10px',}}>
                                    <Col xs={8} sm={8} md={8}>
                                        <p style={{ margin: 0, color: '#5D6063',fontWeight: 'bolder' }}>Intiated By&nbsp; </p>
                                    </Col>
                                    <Col xs={16} sm={16} md={16}>
                                        <Typography.Paragraph
                                            style={{ fontWeight: '500', marginBottom: 0 }}
                                        >
                                            {item?.created_by}
                                        </Typography.Paragraph>
                                    </Col>
                                </Row>
                                {/* <Row gutter={24}>
                                    <Col xs={24} sm={24} md={24}>
                                        <div className={classNames("", {
                                            "width-action-small": windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md",
                                        })} style={{ display: "flex", justifyContent: "space-evenly" }}>
                                            <div
                                                onClick={() => {
                                                    console.log(item, "record")
                                                    !check(item) && submitData(item)
                                                }}
                                                style={{ margin: '0 3px' }}
                                            >
                                                {check(item) ?
                                                    <Tooltip title={checkAllFieldsFilled(item) ? "Change at least one field to Submit a Follow-up" : "Fill all the fields to Submit a Follow-up"}>
                                                        <CheckCircleFilled className="disabled-cursor" style={{ color: 'grey', fontSize: '20px' }} />
                                                    </Tooltip>
                                                    : <Tooltip title={"Submit the Follow-up"}><a
                                                        className={classNames('', {
                                                            "disabled-cursor": check(item)
                                                        })}
                                                    ><CheckCircleFilled style={{ fontSize: '20px' }} /></a>
                                                    </Tooltip>
                                                }
                                            </div>
                                            <div
                                                onClick={() => {
                                                    checkAllFieldsFilled(item) && handleDropdownChange(2, "mail_type", "Compose Mail", index)
                                                }}
                                                style={{ margin: '0 3px' }}
                                            >
                                                {!checkAllFieldsFilled(item) ?
                                                    <Tooltip title={"Fill all the fields to Compose a mail"}>
                                                        <MailFilled style={{ color: 'grey', fontSize: '20px' }} />
                                                    </Tooltip>
                                                    : <Tooltip title={"Compose a mail"}><a
                                                        className={classNames('', {
                                                            "disabled-cursor": !checkAllFieldsFilled(item)
                                                        })}
                                                    ><MailFilled style={{ fontSize: '20px' }} /></a>
                                                    </Tooltip>
                                                }
                                            </div>
                                            <div className="dropdown-follow-up">
                                                <Dropdown
                                                    menu={{
                                                        items,
                                                        selectable: true,
                                                    }}
                                                    dropdownRender={(menu) => (
                                                        <div style={contentStyle}>
                                                            {item?.created && (
                                                                <>
                                                                    <Space
                                                                        className="pointer"
                                                                        style={{
                                                                            padding: 10,
                                                                        }}
                                                                        onClick={() => {
                                                                            traceCurrentMail(index);
                                                                        }}
                                                                    >
                                                                        Trace mail
                                                                    </Space>
                                                                    <br />
                                                                    <Divider
                                                                        style={{
                                                                            margin: 0,
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                            <Space
                                                                className="pointer"
                                                                style={{
                                                                    padding: 10,
                                                                }}
                                                            >
                                                                <Popconfirm
                                                                    title="Sure to delete?"
                                                                    onConfirm={() => handleDelete(item.key, item)}
                                                                >
                                                                    Delete
                                                                </Popconfirm>
                                                            </Space>
                                                        </div>
                                                    )}
                                                >
                                                    <Typography.Link >
                                                        <Space>
                                                            <MoreOutlined style={{ fontSize: '20px' }} />
                                                        </Space>
                                                    </Typography.Link>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Col>
                                </Row> */}
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    )
}
export default FollowUpCards;