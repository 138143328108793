import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ServiceUtils } from '../../../../../Shared/Utils/ServiceUtils';
import { alertService } from '../../../../../Shared/Utils/ToasterUtils';
import { Table, Badge, Tooltip } from "antd";
import './CongressMeetings.scss';




const CongressMeetings = ({menuId}) => {
    const [activityDetails, setActivityDetails] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchCongressMeetingDetails();
    },[])

    const fetchCongressMeetingDetails = () => {
        let payload = {
          profileId: id,
          sectionId: Number(menuId),
        };
        ServiceUtils.postRequest("kolProfile", payload).then(
          (response) => {
            if (response?.status === 200) {
              const columns = generateColumns(
                response.data?.data?.headerContent
              );
              setActivityDetails({
                bodyContent: response.data?.data?.bodyContent,
                headerContent: columns,
                user_name: response.data?.data?.user_name
              });
            } else if (response?.status === 401) {
              navigate("/logout");
            } else {
              alertService.error(response?.data?.message);
            }
          },
          (error) => {
            alertService.error("Error while fetching user role.");
            console.log(error);
          }
        );
      };
      console.log(activityDetails,"activityDetails")

      const generateColumns = (headerContent) => {
        return headerContent.map((column) => {
          // Render the "conference" column with clickable conf_route
          if (column.dataIndex === "conference") {
            return {
              title: column.title,
              dataIndex: column.dataIndex,
              key: column.key,
              render: (conference, record) => (
                <span className="link" onClick={()=>navigate(record.conf_route)}>{conference}</span>
              ),
            };
          }
      
          // Render the "meeting_name" column with clickable mtg_route
          else if (column.dataIndex === "meeting_name") {
            return {
              title: column.title,
              dataIndex: column.dataIndex,
              key: column.key,
              render: (meeting_name, record) => (
                <span className="link" onClick={()=>navigate(record.mtg_route)}>{meeting_name}</span>
              ),
            };
          }
      
          // Default case
          else {
            return {
              title: column.title,
              dataIndex: column.dataIndex,
              key: column.key,
            };
          }
        });
      };

       // Apply row color based on the "color" field
  const onRow = (record) => {
    return {
      style: {
        backgroundColor: record.color || "transparent",
        transition: "background-color 0.3s ease", 
      },
    };
  }

    return (
        <div className='congressMeetingsContainer' style={{ width: "100%" }}>
            <p className="headerLabel">Congress Meetings {activityDetails?.user_name && `- ${activityDetails?.user_name}`}</p>
            <Table
                columns={activityDetails?.headerContent}
                dataSource={activityDetails?.bodyContent}
                onRow={onRow}
            />
        </div>
    )
}

export default CongressMeetings;