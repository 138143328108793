/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Table,
  Select,
  Row,
  Col,
  Dropdown,
  Space,
  Divider,
  theme,
  Typography,
  Tooltip
} from "antd";
import { MoreOutlined, CheckCircleFilled, MailFilled, EnterOutlined, PlusOutlined } from "@ant-design/icons";
import { Option } from "antd/es/mentions";
import DrawerComponent from "../Drawer/Drawer";
import "./FollowUp.scss";
import { getDecryptSession } from "../../../../Shared/Utils/CommonUtils";
import { ServiceUtils } from "../../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../../Shared/Utils/ToasterUtils";
import { useSharedState } from "../../../../Shared/Services/WindowSizeService";
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import useWindowSize from '../../../../Shared/Utils/windowResizeUtils';
import FollowUpCards from "./FollowUpCards";

export default function FollowUp({ ...props }) {
  const { width } = useWindowSize();
  const isIpad = width <= 820;
  const isMobile = width <= 430;
  const { TextArea } = Input;
  const EditableContext = React.createContext(null);
  const navigate = useNavigate();
  const windowSize$ = useSharedState();
  // console.log(windowSize$);
  const [tableData, setTableData] = useState({ headerContent: [], bodyContent: [] })
  const [backupTableData, setBackupTableData] = useState();
  const getUserDetails = () => {
    const session = localStorage.getItem("arcutis-user");
    const userDetails = getDecryptSession(session);
    return userDetails;
  }
  const [userDetails, setUserDetails] = useState(getUserDetails());
  const tableDataRef = useRef();
  tableDataRef.current = tableData;
  useEffect(() => {
    getListOfFollowups();
    // console.log(userDetails);
  }, [])
  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };
  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current?.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };
    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
      }
    };
    let childNode = children;
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
  };

  // console.log(props?.meetingID);
  const [meetingID, setMeetingID] = useState(props?.meetingID);
  // console.log(meetingID);
  const [actionDetails, setActionsDetails] = useState(props?.actionDetails);
  const [currentAction, setCurrentAction] = useState();
  const [drawerState, setDrawerState] = useState();
  const [mailComposeInputs, SetMailComposeInputs] = useState();
  const [selectedMeeting, setSelectedMeeting] = useState({});
  const [validateMailFlag, setValidateMailFlag] = useState();
  const [currentIndex, setCurrentIndex] = useState();
  const [formData, setFormData] = useState({
    action_on: "",
    action_assigned_to: "",
    action_status: "",
    id: "",
    type: "save",
    mail_type: "",
  });
  const { useToken } = theme;
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const items = [
    {
      label: <Space>Edit</Space>,
      key: "0",
      evtype: "edit",
    },
    {
      label: <Space>Delete</Space>,
      key: "1",
      evtype: "delete",
    },
  ];

  const changeColumnStrcuture = () => {
    // console.log(tableData,'tabledaa')
    let tempHeaderData = [...tableData?.headerContent];
    let tempBodyData = [...tableData?.bodyContent];
    let reqColumns = JSON.parse(
      JSON.stringify(tempHeaderData)
    );
    reqColumns?.map((el) => {
      if (el.key === "action_on") {
        el.render = (_, record, index) => (
          <div className="action-on">
            <Select
              placeholder="Select Action on"
              defaultValue={record?.action_on ? record?.action_on : undefined}
              value={record?.action_on ? record?.action_on : undefined}
              style={{ width: "100%" }}
              disabled={!userDetails?.role_access?.home?.conferences?.edit || props?.followUpRequired}
              maxTagCount="responsive"
              onChange={
                (value, label) =>
                  handleDropdownChange(value, "action_on", label, record, index)
                // console.log(dataSource)
              }
            >
              {actionDetails &&
                actionDetails.action_on.length > 0 &&
                actionDetails.action_on.map((el) => (
                  <Option value={el.value}>{el.label}</Option>
                ))}
            </Select>
          </div>
        );
      } else if (el.key === "action_assigned_to") {
        el.render = (_, record, index) => (
          <Select
            placeholder="Select Action assigned to"
            defaultValue={
              record?.action_assigned_to
                ? record?.action_assigned_to
                : undefined
            }
            disabled={!userDetails?.role_access?.home?.conferences?.edit || props?.followUpRequired}
            value={
              record?.action_assigned_to
                ? record?.action_assigned_to
                : undefined
            }
            style={{ width: "100%" }}
            maxTagCount="responsive"
            onChange={(value, label) =>
              handleDropdownChange(
                value,
                "action_assigned_to",
                label,
                record,
                index
              )
            }
          >
            {actionDetails &&
              actionDetails?.action_assigned_to?.length > 0 &&
              actionDetails?.action_assigned_to.map((el) => (
                <Option value={el.value}>{el.label}</Option>
              ))}
          </Select>
        );
      } else if (el.key === "followup_notes") {
        el.render = (_, record, index) => (
          <TextArea
            disabled={props?.followUpRequired}
            rows={3}
            defaultValue={record?.followup_notes ? record?.followup_notes : undefined}
            value={record?.followup_notes ? record?.followup_notes : undefined}
            style={{ width: "100%" }}
            maxLength={500}
            placeholder="Follow Up Notes"
            autoComplete='off'
            autoSize={{ minRows: 2, maxRows: 6 }}
            onChange={
              (e) =>
                handleDropdownChange(
                  e.target.value,
                  "followup_notes",
                  "",
                  record,
                  index
                )
            }
          />
        );
      }
      else if (el.key === "action_status") {
        el.render = (_, record, index) => (
          <Select
            placeholder="Select Status of Follow-up"
            disabled={!userDetails?.role_access?.home?.conferences?.edit || props?.followUpRequired}
            defaultValue={
              record?.action_status ? record?.action_status : undefined
            }
            value={record?.action_status ? record?.action_status : undefined}
            style={{ width: "100%" }}
            onChange={(value, label) =>
              handleDropdownChange(value, "action_status", label, record, index)
            }
          >
            {actionDetails &&
              actionDetails.action_status.length > 0 &&
              actionDetails.action_status.map((el) => (
                <Option value={el.value}>{el.label}</Option>
              ))}
          </Select>
        );
      } else if (el.key === "mail_type") {
        el.render = (_, record, index) => (
          <Select
            placeholder="Select Mail Type"
            defaultValue={record?.mail_type ? record?.mail_type : undefined}
            value={record?.mail_type ? record?.mail_type : undefined}
            style={{ width: "100%" }}
            onChange={(value, label) =>
              handleDropdownChange(value, "mail_type", label, record, index)
            }
          >
            {actionDetails &&
              actionDetails.mail_type?.length > 0 &&
              actionDetails?.mail_type.map((el) => (
                <Option value={el.value}>{el.label}</Option>
              ))}
          </Select>
        );
      } else if (el.key === "action") {
        el.render = (_, record, index) => (
          <div className={classNames("", {
            "width-action-small": windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md",
          })} style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div>
              <Button onClick={() => {
                submitData(record)
              }} className="edit-meet-button" disabled={check(tableData?.bodyContent[index]) || props?.followUpRequired} type="primary">
                Submit
              </Button>
            </div>
            {!check(tableData?.bodyContent[index]) && !props?.followUpRequired &&
              <div className="dropdown-follow-up">
                <Dropdown
                  menu={{
                    items,
                    selectable: true,
                  }}
                  dropdownRender={(menu) => (
                    <div style={contentStyle}>
                      {record?.created && (
                        <>
                          <Space
                            className="pointer"
                            style={{
                              padding: 10,
                            }}
                            onClick={() => {
                              traceCurrentMail(index);
                            }}
                          >
                            Trace mail
                          </Space>
                          <br />
                          <Divider
                            style={{
                              margin: 0,
                            }}
                          />
                        </>
                      )}
                      <Space
                        className="pointer"
                        style={{
                          padding: 10,
                        }}
                        onClick={() =>
                          handleDropdownChange(2, "mail_type", "Compose Mail", record, index)
                        }
                      >
                        Compose Mail
                      </Space>
                      <br />
                      <Divider
                        style={{
                          margin: 0,
                        }}
                      />
                      <Space
                        className="pointer"
                        style={{
                          padding: 10,
                        }}
                      >
                        <Popconfirm
                          title="Sure to delete?"
                          onConfirm={() => handleDelete(record.key, record)}
                        >
                          Delete
                        </Popconfirm>
                      </Space>
                    </div>
                  )}
                >
                  <Typography.Link >
                    <Space>
                      <MoreOutlined style={{ fontSize: '20px' }} />
                    </Space>
                  </Typography.Link>
                </Dropdown>
              </div>
            }
          </div>
        );
      }
    });
    setdefaultColumns(reqColumns);
    setDataSource(tempBodyData);
  };

  const [defaultColumns, setdefaultColumns] = useState();
  const [dataSource, setDataSource] = useState();

  // useEffect(() => {
  //   changeColumnStrcuture();
  // }, []);

  // const [dataSource, setDataSource] = useState([
  //   {
  //     key: "0",
  //     action_on: 26,
  //     action_assigned_to: 64,
  //     action_status: 1,
  //     mail_type: 1,
  //     created: true,
  //     mail_data: undefined,
  //     id: 109,
  //   },
  //   {
  //     key: "1",
  //     action_on: 25,
  //     action_assigned_to: 65,
  //     action_status: 2,
  //     mail_type: 2,
  //     created: false,
  //     mail_data: undefined,
  //     id: 109,
  //   },
  // ]);

  const [count, setCount] = useState(2);

  const handleDelete = (key, record) => {
    // const newData = dataSource.filter((item) => item.key !== key);
    // setDataSource(newData);
    // let tempBodyData = [...tableData?.bodyContent]
    // const filteredData = tempBodyData.filter((item) => item?.id !== record?.id);
    // setTableData({
    //   ...tableData,
    //   bodyContent:filteredData
    // });
    // console.log(filteredData,'filteredData')
    let payload = {
      data: {
        id: record.id,
        type: 'delete'
      }
    }
    followUpService(payload);
    setDrawerState();
  };

  const getListOfFollowups = () => {
    let payload = {
      meetingId: parseInt(meetingID),
    }
    ServiceUtils.postRequest('listOfFollowups', payload).then(
      (response) => {
        // console.log(response.data.data,'response.data.data')
        if (response.status === 200 && response?.data.status) {
          // console.log(response.data.data,'response.data.data')
          let temp = {
            "bodyContent": [
              {
                "key": 1,
                "action_status": 1,
                "action_on": 27,
                "action_assigned_to": 64,
                "id": 110,
                "mail": "lkunchapu@arcutis.com",
                "created": false,
                "mail_data": null,
                "mail_type": null
              },
              {
                "key": 1,
                "action_status": 2,
                "action_on": 30,
                "action_assigned_to": 65,
                "id": 109,
                "mail": "mkarunakar@arcutis.com",
                "created": false,
                "mail_data": null,
                "mail_type": null
              }
            ],
            "headerContent": [
              {
                "dataIndex": "action_on",
                "key": "action_on",
                "title": "Action"
              },
              {
                "dataIndex": "action_assigned_to",
                "key": "action_assigned_to",
                "title": "Action Assigned To"
              },
              {
                "dataIndex": "action_status",
                "key": "action_status",
                "title": "Action Status"
              },
              {
                "title": "Action",
                "dataIndex": "action",
                "key": "action",
                "width": "15%"
              }
            ]
          }
          setTableData({ ...response.data.data });
          setBackupTableData(JSON.parse(JSON.stringify(response.data.data)))
          setDrawerState();
          // setTableData(temp);
        }
        else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error('Error while fetching data.')
      }
    );
  }
  useEffect(() => {
    changeColumnStrcuture()
  }, [tableData, props.followUpRequired])

  // const defaultColumns = [
  //   {
  //     title: "Action on",
  //     dataIndex: "action_on",
  //     //   width: "30%",
  //     //   editable: true,
  //     render: (_, record, index) => (
  //       <Select
  //         placeholder="Select Action on"
  //         defaultValue={record?.action_on ? record?.action_on : undefined}
  //         value={record?.action_on ? record?.action_on : undefined}
  //         style={{ width: "100%" }}
  //         maxTagCount="responsive"
  //         onChange={(value, label) =>
  //           handleDropdownChange(value, "action_on", label, record, index)
  //         }
  //       >
  //         {actionDetails &&
  //           actionDetails.action_on.length > 0 &&
  //           actionDetails.action_on.map((el) => (
  //             <Option value={el.value}>{el.label}</Option>
  //           ))}
  //       </Select>
  //     ),
  //   },
  //   {
  //     title: "Action assigned to",
  //     dataIndex: "action_assigned_to",
  //     //   editable: true,
  //     render: (_, record, index) => (
  //       <Select
  //         placeholder="Select Action assigned to"
  //         defaultValue={
  //           record?.action_assigned_to ? record?.action_assigned_to : undefined
  //         }
  //         value={
  //           record?.action_assigned_to ? record?.action_assigned_to : undefined
  //         }
  //         style={{ width: "100%" }}
  //         maxTagCount="responsive"
  //         onChange={(value, label) =>
  //           handleDropdownChange(
  //             value,
  //             "action_assigned_to",
  //             label,
  //             record,
  //             index
  //           )
  //         }
  //       >
  //         {actionDetails &&
  //           actionDetails?.action_assigned_to?.length > 0 &&
  //           actionDetails?.action_assigned_to.map((el) => (
  //             <Option value={el.value}>{el.label}</Option>
  //           ))}
  //       </Select>
  //     ),
  //   },
  //   {
  //     title: "Status of Follow-up",
  //     dataIndex: "action_status",
  //     //   editable: true,
  //     render: (_, record, index) => (
  //       <Select
  //         placeholder="Select Status of Follow-up"
  //         defaultValue={
  //           record?.action_status ? record?.action_status : undefined
  //         }
  //         value={record?.action_status ? record?.action_status : undefined}
  //         style={{ width: "100%" }}
  //         onChange={(value, label) =>
  //           handleDropdownChange(value, "action_status", label, record, index)
  //         }
  //       >
  //         {actionDetails &&
  //           actionDetails.action_status.length > 0 &&
  //           actionDetails.action_status.map((el) => (
  //             <Option value={el.value}>{el.label}</Option>
  //           ))}
  //       </Select>
  //     ),
  //   },
  //   {
  //     title: "Mail Type",
  //     dataIndex: "mail_type",
  //     //   editable: true,
  //     render: (_, record, index) => (
  //       <Select
  //         placeholder="Select Mail Type"
  //         defaultValue={record?.mail_type ? record?.mail_type : undefined}
  //         value={record?.mail_type ? record?.mail_type : undefined}
  //         style={{ width: "100%" }}
  //         onChange={(value, label) =>
  //           handleDropdownChange(value, "mail_type", label, record, index)
  //         }
  //       >
  //         {actionDetails &&
  //           actionDetails.mail_type?.length > 0 &&
  //           actionDetails?.mail_type.map((el) => (
  //             <Option value={el.value}>{el.label}</Option>
  //           ))}
  //       </Select>
  //     ),
  //   },
  //   {
  //     title: "Action",
  //     dataIndex: "action",
  //     render: (_, record, index) =>
  //       dataSource.length >= 1 ? (
  //         <div style={{ display: "flex", justifyContent: "space-evenly" }}>
  //           {/* <>
  //             <Popconfirm
  //               title="Sure to delete?"
  //               onConfirm={() => handleDelete(record.key)}
  //             >
  //               <a>Delete</a>
  //             </Popconfirm>
  //           </>
  //           {record?.created && (
  //             <div onClick={() => {
  //               traceCurrentMail(index);
  //             }}>
  //               <a>Trace Mail</a>
  //             </div>
  //           )} */}
  //           <div
  //             onClick={() => {
  //               submitData(index);
  //             }}
  //           >
  //             <a>Submit</a>
  //           </div>
  //           <div className="dropdown-follow-up">
  //             {/* <Tooltip title={"Edit " + meet?.conference_name}>
  //                                 <EditOutlined className="pointer" style={{marginRight:'20px'}} onClick={()=>{editConference(meet?.conference_id)}}/>
  //                               </Tooltip>
  //                               <Tooltip title={"Delete " + meet?.conference_name} onClick={()=>{deleteConference(meet?.conference_id)}}>
  //                                 <DeleteOutlined className="pointer"/>
  //                               </Tooltip> */}

  //             <Dropdown
  //               menu={{
  //                 items,
  //                 selectable: true,
  //                 // defaultSelectedKeys: ['3'],
  //               }}
  //               dropdownRender={(menu) => (
  //                 <div style={contentStyle}>
  //                   {/* {React.cloneElement(menu, {
  //                                       style: menuStyle,
  //                                     })}
  //                                     <Divider
  //                                       style={{
  //                                         margin: 0,
  //                                       }}
  //                                     /> */}
  //                   {/* <Typography.Link> */}
  //                   {record?.created && (
  //                     <>
  //                       <Space
  //                         className="pointer"
  //                         style={{
  //                           padding: 10,
  //                         }}
  //                         onClick={() => {
  //                           traceCurrentMail(index);
  //                         }}
  //                         // onClick={()=>{editConference(meet?.conference_id)}}
  //                       >
  //                         Trace mail
  //                       </Space>
  //                       <br />
  //                       <Divider
  //                         style={{
  //                           margin: 0,
  //                         }}
  //                       />
  //                     </>
  //                   )}
  //                   <Space
  //                     className="pointer"
  //                     style={{
  //                       padding: 10,
  //                     }}
  //                     // onClick={()=>{deleteConference(meet?.conference_id)}}
  //                   >
  //                     {/* <Button type="primary">Click me!</Button> */}
  //                     {/* Delete */}
  //                     <Popconfirm
  //                       title="Sure to delete?"
  //                       onConfirm={() => handleDelete(record.key)}
  //                     >
  //                       {/* <a>
  //                                           </a> */}
  //                       Delete
  //                     </Popconfirm>
  //                     {/* <DeleteOutlined/> */}
  //                   </Space>
  //                   {/* </Typography.Link> */}
  //                 </div>
  //               )}
  //             >
  //               <Typography.Link>
  //                 <Space>
  //                   {/* Selectable */}
  //                   More
  //                   <MoreOutlined />
  //                 </Space>
  //               </Typography.Link>
  //             </Dropdown>
  //           </div>
  //         </div>
  //       ) : null,
  //   },
  // ];

  // useEffect(()=>{
  //   console.log(dataSource);
  // },[dataSource])

  const handleAdd = () => {
    const newData = {
      key: count + 1,
      action_on: "",
      action_assigned_to: "",
      action_status: "",
      mail_type: "",
      created: undefined,
      mail_data: undefined,
      followup_notes: "",
      id: "",
      edit: true,
      // created_by:userDetails?.user_name,
    };
    setDataSource([...dataSource, newData]);
    let tempBodyData = [...tableData?.bodyContent];
    tempBodyData.unshift(newData);
    setTableData({
      ...tableData,
      bodyContent: tempBodyData
    })

    // console.log(dataSource);
    setCount(count + 1);
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const validateSubmitButton = () => {

  }

  const handleChange = (dataObj) => {
    try {
      switch (dataObj?.type) {
        case 'dropdownChange':
          handleDropdownChange(dataObj?.value, dataObj?.key, dataObj?.label, dataObj?.record, dataObj?.index);
          break;
        case 'submitData':
          submitData(dataObj?.bodyContent)
          break;
        case 'handleDelete':
          handleDelete(dataObj?.key, dataObj?.record)
          break;
        case 'traceCurrentMail':
          traceCurrentMail(dataObj?.index);
          break;
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleDropdownChange = (value, key, label, record, index) => {
    let tempTableData = [...tableDataRef?.current?.bodyContent];
    tempTableData[index][key] = value;
    // console.log(key);
    if (checkSubmitStatus(record, index)) {
      // console.log('k')
      tempTableData[index]['change'] = true;
    }
    else {
      // console.log('l')
      tempTableData[index]['change'] = false;
    }
    // console.log(backupTableData?.bodyContent)
    // console.log(value);
    setTableData({
      ...tableData,
      bodyContent: tempTableData
    })
    if (value === 2 && key === 'mail_type') {
      setDrawerState("composeMail");
    }
    setCurrentIndex(index);
  };

  const traceCurrentMail = (index) => {
    setCurrentIndex(index);
    setDrawerState("traceMail");
    // event.preventDefault();
    // setDataSource(dataSource);
  };

  const checkMailFlag = (event) => {
    setValidateMailFlag(event);
    if (!event && dataSource[currentIndex]?.mail_type === 2) {
      dataSource[currentIndex].mail_type = 1;
      setDataSource(dataSource);
    }
  };

  const validateFields = (ind) => {
    let valiadateFlag = true;
    // for (let i = 0; i < dataSource?.length; i++) {
    //   console.log(dataSource[i]);
    //   for (let [key, value] of Object.entries(dataSource[i])) {
    //     console.log(key, value);
    //     if (
    //       key === "action_on" ||
    //       key === "action_assigned_to" ||
    //       key === "action_status" ||
    //       key === "mail_type"
    //     ) {
    //       if (!value) {
    //         valiadateFlag = false;
    //       }
    //     } else if (key === "mail_data") {
    //       if (Number(dataSource[i]?.mail_type) === 2) {
    //         if (!value) {
    //           valiadateFlag = false;
    //         } else {
    //           console.log("j");
    //         }
    //       }
    //     }
    //   }
    // }
    for (let [key, value] of Object.entries(dataSource[ind])) {
      // console.log(key, value);
      if (
        key === "action_on" ||
        key === "action_assigned_to" ||
        key === "action_status" ||
        key === "mail_type"
      ) {
        if (!value) {
          valiadateFlag = false;
        }
      } else if (key === "mail_data") {
        if (Number(dataSource[ind]?.mail_type) === 2) {
          if (!value) {
            valiadateFlag = false;
          } else {
            // console.log("j");
          }
        }
      }
    }
    return valiadateFlag;
  };

  const submitData = (data) => {

    let tempData = JSON.parse(JSON.stringify(data));
    if (!tempData?.mail_type) {
      tempData.mail_type = 1
    }
    let payload = {
      data: {
        ...tempData,
        meetingId: meetingID,
        user_email: userDetails?.user_email,
        user_id: userDetails?.user_id,
        type: data?.id ? 'edit' : 'save',
        user_name: userDetails?.user_name,
        created_time: new Date,
        created_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      attachments: mailComposeInputs?.attachments,
    }

    handleSubmit(payload);

  };

  const handleSubmit = (payload) => {
    if (payload.action_on == '' || payload.action_assigned_to == '' || payload.action_status == '' || payload.composeMail == '') {
      alertService.error('Fill all the required fields')
      return;
    }
    followUpService(payload)
    setDrawerState();
  };

  const followUpService = (payload) => {
    ServiceUtils.postRequest('saveFollowup', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          getListOfFollowups();
          setFormData({
            action_on: '',
            action_assigned_to: '',
            action_status: '',
            id: '',
            type: 'save',
            composeMail: ''
          });
          SetMailComposeInputs();
        }
        else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error('Error while fetching Objective.')
      }
    );
  }
  const setMailInputsData = (data, index) => {
    // SetMailComposeInputs(data);
    // console.log(index);
    // console.log(data);
    // dataSource[index]["mail_data"] = data;
    // dataSource[index]["change"] = true;
    // setDataSource(dataSource);

    getListOfFollowups();
    setFormData({
      action_on: '',
      action_assigned_to: '',
      action_status: '',
      id: '',
      type: 'save',
      composeMail: ''
    });
    SetMailComposeInputs();
    setDrawerState();
  };

  const checkSubmitStatus = (data, index) => {
    let reqFlag = true;
    if (backupTableData && (index >= 0)) {
      if (data) {
        if (data?.edit) {
          if (data?.action_status && data?.action_assigned_to && data?.action_on) {
            reqFlag = true;
          }
          else {
            reqFlag = false;
          }
        }
        else if (data?.created === false || data?.created === true) {
          let backUpData = JSON.parse(JSON.stringify(backupTableData?.bodyContent[index]))
          if (Number(backUpData?.action_assigned_to) !== Number(data?.action_assigned_to) || Number(backUpData?.action_on) !== Number(data?.action_on) || Number(backUpData?.action_status) !== Number(data?.action_status)) {
            reqFlag = true;
          }
          else {
            reqFlag = false;
          }
        }
      }
    }
    console.log(reqFlag)
    return reqFlag;
  }

  const check = (rec) => {
    // console.log(!rec?.change);
    return !rec?.change
  }

  const checkAllFieldsFilled = (data) => {
    // console.log(data)
    let retFlag = false;
    if (data?.action_assigned_to && data?.action_on && data?.action_status) {
      retFlag = true;
    }
    return retFlag;
  }

  return (
    <div>
      <div>
        <div>
          <Row>
            <Col
              xs={{ flex: "100%" }}
              sm={{ flex: "100%" }}
              md={{ flex: "100%" }}
              lg={{ flex: "100%" }}
              xl={{ flex: "100%" }}
            >
              <div style={{ display: "flex", justifyContent: "end" }}>
                {/* <Button
                  className="edit-meet-button"
                  onClick={submitData}
                  type="primary"
                  style={{
                    marginBottom: 16,
                    marginTop: 20,
                  }}
                >
                  Submit
                </Button> */}
                {userDetails?.role_access?.home?.conferences?.edit && <Button
                  className="edit-meet-button"
                  disabled={props?.followUpRequired}
                  onClick={handleAdd}
                  type="primary"
                  style={{
                    marginBottom: 8,
                    marginTop: 8,
                  }}
                >
                  <PlusOutlined fill="white" style={{ fontSize: '15px' }} />Create a Follow-up
                </Button>}
              </div>
            </Col>
          </Row>
        </div>
        <>
          {
            isIpad ? (
              <FollowUpCards dataSource={dataSource}
                columns={columns} actionDetails={actionDetails} followUpRequired={props?.followUpRequired}
                userDetails={userDetails} tableDataRef={tableDataRef} onAction={handleChange} />
            ) : (
              <div className={classNames("", {
                "follow-container":
                  windowSize$ === "lg" ||
                  windowSize$ === "xl" ||
                  windowSize$ === "xxl",
                "follow-container-small": windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md",
              })}>
                <Table
                  // components={components}
                  rowClassName={() => "editable-row"}
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  scroll={{
                    y: "calc(100vh - 65vh)",
                  }}
                />
              </div>
            )
          }
        </>
      </div>
      {drawerState === "composeMail" && (
        <DrawerComponent
          passData="composeMail"
          meetingID={meetingID}
          currentItem={currentIndex}
          defaultSubject={
            currentAction
              ? selectedMeeting["meetingTitle"] + " - " + currentAction
              : selectedMeeting["meetingTitle"]
          }
          closeDrawer={(e) => {
            setDrawerState("");
          }}
          followUpDetails={dataSource[currentIndex]}
          userDetails={userDetails}
          mailInputs={dataSource[currentIndex]?.mail_data}
          setMailInputs={(e, index) => {
            setMailInputsData(e, index);
          }}
          setValidatedMailFlag={(e) => {
            checkMailFlag(e);
          }}
        />
      )}
      {drawerState === "traceMail" && (
        <DrawerComponent
          passData="traceMail"
          meetingID={meetingID}
          followUpID={tableData?.bodyContent[currentIndex]?.id}
          closeDrawer={(e) => {
            setDrawerState("");
          }}
        />
      )}
    </div>
  );
}
