import { Card,Select,Table } from 'antd';
import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './MeetingFollowUp.scss'
import { alertService } from '../../../../Shared/Utils/ToasterUtils';
import { ServiceUtils } from '../../../../Shared/Utils/ServiceUtils';
import BreadcrumbList from '../../../../Shared/Components/Breadcrumb/Breadcrumb';
import classNames from 'classnames';
import { useSharedState } from '../../../../Shared/Services/WindowSizeService';

const MeetingFollowUp = () => {
    const [hierarchy, setHierarchy] = useState([{ label: 'FollowUp', link: '' }]);
    const [tableData, setTableData] = useState({
        headerContent: [],
        bodyContent: []
    });
    const [defaultOptions, setDefaultOptions] = useState(['Meeting','Conference','General']);
    const dropdownOptions = [
        {
            label:"Meeting",
            value:"Meeting"
        },
        {
            label:"Conference",
            value:"Conference"
        },
        {
            label:"General",
            value:"General"
        }
    ];
    const navigate = useNavigate();
    const windowSize$ = useSharedState();


    useEffect(()=>{
        fetchTableData();
    },[defaultOptions]);

    const onFilterChange = (value) => {
        setDefaultOptions(value);
    };

    const fetchTableData = () => {
        let payload = {
            filters: defaultOptions
        }
        ServiceUtils.postRequest("followups", payload).then(
            (response) => {
                if (response.status === 200 && response?.data.status) {
                    setTableData(response?.data?.data);
                } 
                else if(response.status === 401 ){
                    navigate('/logout');
                }
                else {
                    alertService.error(response.data.message);
                }
            },
            (error) => {
                alertService.error("Error while fetching Data.");
            }
        );
    };

    const filter = (input, option) => {
        return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
    };

    return (
        <div className="MeetingFollowUp">
            <Card
                style={{ width: "100%", height: "fit-content" }}
                bodyStyle={{ padding: "10px" }}
            >
                <BreadcrumbList active={hierarchy} />
            </Card>
            <Card>
                <div className="filters">
                    <Select
                        mode="multiple"
                        allowClear
                        style={{
                            width: "25%",
                            margin: "5px"
                        }}
                        showSearch
                        placeholder="Please select"
                        onChange={onFilterChange}
                        value={defaultOptions}
                        options={dropdownOptions}
                        defaultValue={defaultOptions}
                        filterOption={filter}
                        maxTagCount={3}
                    />
                </div>
                <div className={classNames("", {
                    "follow-container":
                        windowSize$ === "lg" ||
                        windowSize$ === "xl" ||
                        windowSize$ === "xxl",
                    "follow-container-small": windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md",
                })}>
                    <Table
                        columns={tableData?.headerContent}
                        dataSource={tableData?.bodyContent}
                        bordered
                        pagination={false}
                        scroll={{
                            y: "calc(100vh - 270px)",
                        }}
                    />
                </div>
            </Card>
        </div>
    )
}

export default MeetingFollowUp;