import React, { useState, useCallback, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Input,
  Typography,
  Card,
  Avatar,
  Space,
  Empty,
  Image,
  Table,
  Tooltip,
  Dropdown,
  message,
  Divider,
  theme,
  Layout,
  Collapse,
  Checkbox,
  Modal,
  Radio,
  Menu,
  Select,
  Tree
} from "antd";
import {
  SearchOutlined,
  CopyOutlined,
  CaretRightOutlined,
  CloseOutlined,
  PlusOutlined,
  FilterOutlined,
  UserOutlined,
  ProductOutlined,
  BarsOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import classNames from "classnames";
import "../../UserLogin/UserLogin.scss";
import { useSharedState } from "../../../Shared/Services/WindowSizeService";
import { useNavigate } from "react-router-dom";
import { ServiceUtils } from "../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../Shared/Utils/ToasterUtils";
import "./KOL.scss";
import Sider from "antd/es/layout/Sider";
import { Content, Footer, Header } from "antd/es/layout/layout";
import SVGIcon from "../../../Shared/Components/SVGIcon/SVGIcon";
import useWindowSize from "../../../Shared/Utils/windowResizeUtils";
import { debounce } from "../../../Shared/Utils/CommonUtils";
import BreadcrumbList from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import { getDecryptSession } from "../../../Shared/Utils/CommonUtils";

export default function KOL(props) {
  const windowSize$ = useSharedState();
  const navigate = useNavigate();
  const { Title, Text } = Typography;
  const Search = Input.Search;
  // const userDetails = props?.userDetails;
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState();
  const [tableFilterData, setTableFilter] = useState({
    page: 1,
    size: 16,
    // sortOrder: "asc",
    // sortBy: "name",
  });
  const [statusData, setStatusData] = useState({
    data: null,
    loadedPages: [],
    loading: false,
    disable: false,
    end: false,
  });

  const [searchData, setSearchData] = useState();
  const [searchTableFilterData, setSearchTableFilter] = useState({
    page: 1,
    size: 16,
    // sortOrder: "asc",
    // sortBy: "name",
  });
  const [searchStatusData, setSearchStatusData] = useState({
    data: null,
    loadedPages: [],
    loading: false,
    disable: false,
    end: false,
  });
  const [searchText, setSearchText] = useState("");
  const [searchFlag, setSearchFlag] = useState(false);
  const [filterSelectedData, setFilterSelectedData] = useState({
      "searchText": ""
  });
  const [filterConfig, setFilterConfig] = useState([]);
  const [showAttributeModel, setShowAttributeModel] = useState(false);
  const [view, setView] = useState("card");
  const [attributes,setAttributes] = useState([]);
  const [selectedAttributes,setSelectedAttributes] = useState([]);
  const [backUpSelectedAttributes , selectBackUpSelectedAttributes] = useState([]);
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const columns = [
    {
      title: "Name",
      dataIndex: "full_name",
      key: "name",
      render: (_,record) => <a onClick={()=>navigate("profileDetails" + "/" + record.profile_id)}>{record?.full_name}</a>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "designation",
    },
    {
      title: "Mobile",
      dataIndex: "phone_number",
      key: "attendeeId",
    },
    {
      title: "Area Of Expertise",
      dataIndex: "area_of_expertise",
      key: "department",
    },
    {
      title: "Degree",
      dataIndex: "degree",
      key: "email",
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <Dropdown overlay={menu(record)} trigger={["click"]}>
          <Button type="link" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const [tableColumns , setTableColumns] = useState(columns);
  const [showEditButtons,setShowEditButtons] = useState(false);
  const [showBulkEditModal , setShowBulkEditModal] = useState(false);
  const [hierarchy,setHierarchy] = useState([{ label: 'Profiles', link: '' }]);
  const session = localStorage.getItem("arcutis-user");
  const userDetails = getDecryptSession(session);
  const [selectedKeys, setSelectedKeys] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(()=>{
    fetchData();
  },[filterSelectedData])



  const setBg = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return "#" + randomColor;
  };

  const handleSearchInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const addNew = () => {
    navigate("addProfile");
  };

  const onSelectCard = (data, startDate) => {
    localStorage.setItem(
      "meetingListFilter",
      JSON.stringify({
        meetingFilter: [],
        attendeeFilter: [],
        objectiveFilter:[],
        kolFilter:[],
        followUpFilter:[],
        calenderDate: startDate ? new Date(startDate) : new Date(),
        view: "day",
        conferenceId: null,
      })
    );
    navigate(data);
  };

  const handleScroll = (e, seaflag) => {
    if (!statusData?.disable || !searchStatusData?.disable) {
      const scrollPercent =
        (e.target?.scrollTop /
          (e.target?.scrollHeight - e.target?.offsetHeight)) *
        100;
      if (seaflag) {
        const page = searchTableFilterData?.page + 1;
        if (
          scrollPercent > 70 &&
          !searchStatusData?.loading &&
          !searchStatusData?.loadedPages?.includes(page) &&
          !searchStatusData?.end
        ) {
          const tempFilter = {
            ...searchTableFilterData,
            page: page,
            sort_by: searchTableFilterData?.sortBy,
            sort_order: searchTableFilterData?.sortOrder,
          };
          fetchData(
            {
              page: page,
              sort_by: searchTableFilterData?.sortBy,
              sort_order: searchTableFilterData?.sortOrder,
            },
            "null",
            seaflag
          );
          setSearchTableFilter(tempFilter);
        }
      } else {
        const page = tableFilterData?.page + 1;
        if (
          scrollPercent > 70 &&
          !statusData?.loading &&
          !statusData?.loadedPages?.includes(page) &&
          !statusData?.end
        ) {
          const tempFilter = {
            ...tableFilterData,
            page: page,
            sort_by: tableFilterData?.sortBy,
            sort_order: tableFilterData?.sortOrder,
          };
          fetchData(
            {
              page: page,
              sort_by: tableFilterData?.sortBy,
              sort_order: tableFilterData?.sortOrder,
            },
            "null",
            seaflag
          );
          setTableFilter(tempFilter);
        }
      }
    }
  };

  useEffect(() => {
    if (searchFlag && searchText) {
      fetchData(
        {
          page: searchTableFilterData?.page,
          sort_by: searchTableFilterData?.sortBy,
          sort_order: searchTableFilterData?.sortOrder,
        },
        "",
        true
      );
    }
  }, [searchFlag]);

  const submitSearch = () => {
    if (searchText?.length) {
      let temp = {
        data: null,
        loadedPages: [],
        loading: false,
        disable: false,
        end: false,
      };
      setSearchStatusData(JSON.parse(JSON.stringify(temp)));
      if (searchFlag) {
        fetchData(
          {
            page: 1,
            sort_by: searchTableFilterData?.sortBy,
            sort_order: searchTableFilterData?.sortOrder,
          },
          "",
          true
        );
      } else {
        setSearchFlag(true);
      }
    } else {
      setSearchFlag(false);
    }
  };

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      submitSearch();
    }
  };

  const fetchData = (input, type, where,paginationType) => {
    if (where) {
      setSearchStatusData({ ...searchStatusData, loading: true });
    } else {
      setStatusData({ ...statusData, loading: true });
    }
    let payload = {}
    if(filterSelectedData?.clear_filters){
       payload = {
        page: where ? searchTableFilterData?.page : tableFilterData?.page,
        size: where ? searchTableFilterData?.size : tableFilterData?.size,
        filters_json:{searchText:searchText},
        attributes_list:selectedAttributes,
        clear_filters:filterSelectedData?.clear_filters,
        ...input,
      }
    }else{
     payload = {
      page: where ? searchTableFilterData?.page : tableFilterData?.page,
      size: where ? searchTableFilterData?.size : tableFilterData?.size,
      filters_json:{...filterSelectedData, searchText:searchText},
      attributes_list:selectedAttributes,
      //   emp_id: userDetails.emp_id,
      ...input,
    };
  }
    // const showLoader = payload?.page === 1;
    ServiceUtils.postRequest("listOfProfiles", payload).then(
      (_response) => {
        if (_response.status === 200) {
          if (_response?.data) {
            // const response =  _response?.data?.data ? _response?.data?.data : _response?.data?.data?.body;
            const response = _response?.data?.data?.attendeeData;
            const endFlag = _response?.data?.end;
            if (endFlag) {
              alertService.warning("fetched all the profiles");
            }
            if (where) {
              let result =
                payload?.page === 1 ? [] : searchStatusData?.data || [];
              if(paginationType === 'tablePagination'){
                result = [...response]
              }else{
              if (payload?.page === 1) {
                result = [...[], ...response];
              } else {
                result = [...result, ...response];
              }
            }

              if (type && type === "sort") {
                setSearchData({
                  data: result,
                  loadedPages: [payload?.page],
                  loading: false,
                  disable: response.length === 0,
                });
              } else {
                setSearchStatusData({
                  data: result,
                  loadedPages: [
                    ...searchStatusData?.loadedPages,
                    payload?.page,
                  ],
                  loading: false,
                  disable: response.length === 0,
                  end: endFlag,
                });
              }
              setSearchData({
                data: result,
                filterData: result,
                header: _response?.data?.data?.header,
                total_count: _response?.data?.data?.totalRecords,
              });
            } else {
              let result = payload?.page === 1 ? [] : statusData?.data || [];
              if(paginationType === 'tablePagination'){
                result = [...response]
              }else{
                result = [...result, ...response];
              }
              if (type && type === "sort") {
                setStatusData({
                  data: result,
                  loadedPages: [payload?.page],
                  loading: false,
                  disable: response.length === 0,
                });
              } else {
                setStatusData({
                  data: result,
                  loadedPages: [...statusData?.loadedPages, payload?.page],
                  loading: false,
                  disable: response.length === 0,
                  end: endFlag,
                });
              }
              setData({
                data: result,
                filterData: result,
                header: _response?.data?.data?.header,
                total_count: _response?.data?.data?.totalRecords,
              });
            }
            if (_response?.data?.data?.filters) {
              let filterData = JSON.parse(
                JSON.stringify(_response?.data?.data?.filters?.filterData)
              );
              let filterKeys = JSON.parse(
                JSON.stringify(_response?.data?.data?.filters?.filterKeys)
              );
              getSubmittedFilterStructure(filterData, filterKeys);
            }
            setAttributes([..._response?.data?.data?.attributesList]);
            setSelectedAttributes([..._response?.data?.data?.selectedAttributes]);
            selectBackUpSelectedAttributes([..._response?.data?.data?.selectedAttributes]);
            // setTableData({ data: result, filterData: result, header:_response?.data?.data?.header, total_count: _response?.data?.meta_data?.totalRecords});
          } else {
            alertService.success("Error while getting dashboard data");
          }
        }
      },
      (error) => {
        console.log("Error: dashboard data", error);
      }
    );
  };
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success("Email copied to clipboard!");
      })
      .catch((err) => {
        message.error("Failed to copy email");
        console.error("Could not copy text: ", err);
      });
  };
  const getSubmittedFilterStructure = (data, reqObj) => {
    let filterDataConfig = data;
    let treeNodeData = [];
    filterDataConfig.map(item => {
      if(item?.type === "treeData") {
        treeNodeData = item?.options
      }
    })
    let reqObject = reqObj;
  
    const onSelect = (selectedKeys, info) => {
    };


    const onCheck = (checkedKeys, info, target, treeNodeData) => {
      let payLoadFormat = {};
    
      // function to get child keys for a parent node
      const getChildKeys = (parentNode) => {
        const parentNodeData = treeNodeData?.find(node => node.key === parentNode);
        return parentNodeData && parentNodeData.children
          ? parentNodeData.children.map(child => child.key)
          : [];
      };
    
      // Loop through the checked keys and build the payload
      checkedKeys.forEach((key) => {
        treeNodeData?.forEach((treeData) => {
          // If the checked key is a parent node
          if (treeData.key === key) {
            if (treeData.children && treeData.children.length > 0) {
              // Include all children keys if a parent is selected
              const childKeys = getChildKeys(treeData.key);
    
              // Add the parent node only if it's not already in the payload
              if (!payLoadFormat[treeData.key]) {
                payLoadFormat[treeData.key] = [...new Set(childKeys)];
              } else {
                // If the parent is already in the payload, ensure we only add new children
                payLoadFormat[treeData.key] = [
                  ...new Set([...payLoadFormat[treeData.key], ...childKeys]),
                ];
              }
            } else {
              // If it's a leaf node, just include the key itself
              if (!payLoadFormat[treeData.key]) {
                payLoadFormat[treeData.key] = [treeData.key];
              }
            }
          }
    
          // If the checked key is a child node, check if its parent is selected
          if (treeData.children && treeData.children.some(child => child.key === key)) {
            const parentKey = treeData.key;
    
            // If parent node is not already in the payload, add it with the selected child key
            if (!payLoadFormat[parentKey]) {
              payLoadFormat[parentKey] = [];
            }
    
            // Add the selected child key to the parent in the payload (avoid duplicates)
            payLoadFormat[parentKey] = [
              ...new Set([...payLoadFormat[parentKey], key]),
            ];
          }
        });
      });
    
      // Prepare the request object with the formatted data
       reqObject = { ...reqObject, ...filterSelectedData, [target]: payLoadFormat };
    
      // Set the filterSelectedData state to the new request object
      setSelectedKeys(checkedKeys);
      setFilterSelectedData(reqObject);
    };
    
  
    const onChangeFilters =  (data, key,type) => {
      console.log(data,key,reqObject,"on filter change")
      reqObject = { ...reqObject, ...filterSelectedData, [key]: data };
      setFilterSelectedData({ ...reqObject });
    };

    const filter = (input, option) => {
      return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
    };
    
    const handleChangeFilter = (values,key) => {
      console.log("on filter change")
      reqObject = { ...reqObject, ...filterSelectedData, [key]: values }
      setFilterSelectedData(reqObject);
    };

    filterDataConfig?.map((item) => {
      if (item.type === "checkBox") {
        const getData = () => {
          return [
            <Checkbox.Group
              onChange={(e) => {
                onChangeFilters(e, item?.key,'checkbox');
              }}
            >
              <Space direction="column">
                <Row gutter={16}>
                  {item?.options?.map((option) => (
                    <Col span={24}>
                      <Checkbox
                        value={option?.value}
                        style={{ marginBottom: "3px" }}
                      >
                        {option?.label}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Space>
            </Checkbox.Group>,
          ];
        };
        item["children"] = getData();
      }else if (item.type === "input") {
        const getData = () => {
          return(
            <Search
              placeholder={item?.label}
              allowClear
              enterButton={<SearchOutlined/>}
              style={{margin:"10px 0",fontSize:"13px"}}
              size="middle"
              onSearch={(value)=>onChangeFilters(value, item?.key,'input')}
            />
          )
        };
        item["children"] = getData();
      }
      else if (item.type === "treeData") {
        const getData = () => {
          return (
            <Tree
            checkable
            onCheck={(checkedKeys, info) => {onCheck(checkedKeys, info,item.key,item?.options)}}
            checkedKeys={selectedKeys}
            treeData={item?.options}
            />
          )
        };
        item["children"] = getData();
      } 
      else if (item.type === 'select') {
        const getData = () => {
          return (
            <div className="select-menu" style={{ marginBottom: '15px' }}>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                showSearch
                placeholder="Please select"
                onChange={(e) => {handleChangeFilter(e,item?.key)}}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <div
                      style={{
                        display: "flex",
                        // padding: "0 8px 4px",
                        // gap: "8px",
                      }}
                    >
                    </div>
                  </>
                )}
                options={item?.options}
                defaultValue={item?.defaultValue}
                filterOption={filter}
                maxTagCount={5}
              />
            </div>
          )
        }
        item['children'] = getData();
      }
    });
    setFilterConfig(filterDataConfig);
  };


  const menu = (record) => (
    <Menu>
      <Menu.Item key="edit" onClick={() => handleActions(record, "view")}>
        View
      </Menu.Item>
    </Menu>
  );
  const handleActions = (data, action) => {
    if (action === "view") {
      onSelectCard("profileDetails" + "/" + data.profile_id);
    }
  };

  const handleTablePagination = (pagination) => {
    const page = pagination?.current;
    console.log(pagination,'pagepage')
      if (searchFlag) {
        console.log(pagination?.pageSize,'pagination?.size')
          const tempFilter = {
            ...searchTableFilterData,
            page: page,
            size:pagination?.pageSize,
            sort_by: searchTableFilterData?.sortBy,
            sort_order: searchTableFilterData?.sortOrder,
          };
          fetchData(
            {
              page: page,
              size:pagination?.pageSize,
              sort_by: searchTableFilterData?.sortBy,
              sort_order: searchTableFilterData?.sortOrder,
            },
            "null",
            searchFlag,
            'tablePagination'
          );
          setSearchTableFilter(tempFilter);
      } else {
        console.log(pagination?.pageSize,'pagination?.size')
          const tempFilter = {
            ...tableFilterData,
            page: page,
            size:pagination?.pageSize,
            sort_by: tableFilterData?.sortBy,
            sort_order: tableFilterData?.sortOrder,
          };
          setTableFilter(tempFilter);
          fetchData(
            {
              page: page,
              size:pagination?.pageSize,
              sort_by: tableFilterData?.sortBy,
              sort_order: tableFilterData?.sortOrder,
            },
            "null",
            searchFlag,
            'tablePagination'
          );
      }

  };

  const handleAttributes = (event,attribute) =>{
    let tempAttributes = [...selectedAttributes];
    const index = tempAttributes.indexOf(attribute?.value);
    if (index === -1) {
      tempAttributes.push(attribute?.value);
    } else {
      tempAttributes.splice(index, 1);
    }
    setSelectedAttributes([...tempAttributes])
  }

  const handleAddAttributes=()=>{
    setShowAttributeModel(false);
    let tempData = resetKeys(filterSelectedData,selectedAttributes)
    selectBackUpSelectedAttributes([...selectedAttributes]);
    setFilterSelectedData({...tempData})
  }
  function resetKeys(filtersJson, attributesList) {
    const newFiltersJson = {};
  
    for (const key in filtersJson) {
      if (attributesList.includes(key)) {
        newFiltersJson[key] = filtersJson[key];
      } else {
        newFiltersJson[key] = '';
      }
    }
  
    return newFiltersJson;
  }

  const bulkEdit=(action)=>{
    if(action === 'bulkEdit'){
    setShowEditButtons(true)
    let tempColumns = [...tableColumns]
    tempColumns.unshift({
      title: "Select",
      dataIndex: "checkbox",
      key: "checkbox",
      width: 100, 
      render: (_,record) =>  <Checkbox
      // checked={selectedAttributes.includes(attribute?.value)}
      onChange={(event)=>handleBulkEdit(record,event)}
      value={record?.value}
    >
    </Checkbox>
    })
    console.log(tempColumns,'tempColumns')
    setTableColumns([...tempColumns])
  }else if(action === 'cancel'){
    setShowEditButtons(false)
    let tempColumns = tableColumns?.filter((column)=>{
      return column?.dataIndex !== 'checkbox'
    })
    setTableColumns([...tempColumns])
  }
  }

  const handleBulkEdit=(data,event)=>{
    console.log(data,event,'record')
  }

  const handleBulkEditDetails=()=>{

  }

  return (
    <div className="login-test-KOL">
      <Card
        style={{ width: "100%", height: "fit-content" }}
        bodyStyle={{ padding: "10px" }}
      >
        <BreadcrumbList active={hierarchy} />
      </Card>
      <Layout>
        {showFilter ? (
          <Sider
            width={250}
            style={{
              overflow: "visible",
            }}
          >
            <Layout style={{ height: "-webkit-fill-available" }}>
              <Header
                className="filter-header"
                style={{ padding: "0px !important" }}
              >
                <div className="demo-logo-vertical" />
                <Row gutter={16} style={{ padding: "0px", margin: "0px" }}>
                  <Col span={14}>
                    <b
                      style={{
                        padding: "0 10px",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      <FilterOutlined /> &nbsp; Filters
                    </b>
                  </Col>
                  <Col span={10} style={{ alignSelf: "center" ,display:'flex',justifyContent:"space-between",alignItems:"center"}}>
                  <span onClick={()=>{
                    setSelectedAttributes([]);
                    setFilterSelectedData({clear_filters:true});}} className="clearFilters">Clear Filters</span>
                    <span
                      style={{
                        opacity: "0.5",
                        display: "flex",
                        justifyContent: "end",
                      }}
                      onClick={() => setShowFilter(!showFilter)}
                      className="pointer"
                    >
                      <CloseOutlined />
                    </span>
                  </Col>
                </Row>
                <Divider />
              </Header>
              <Layout>
                <Content
                  className="filter-content"
                  style={{ backgroundColor: "white" }}
                >
                  <Divider />
                  <span
                    className="attributes"
                    onClick={() => setShowAttributeModel(true)}
                  >
                    <PlusOutlined style={{ marginRight: "10px" }} /> Configure filters
                  </span>
                  <Divider />
                  <Collapse
                    defaultActiveKey={["1"]}
                    // onChange={onChangeCollapse}
                    expandIconPosition="end"
                    items={filterConfig}
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                  />
                </Content>
              </Layout>
            </Layout>
          </Sider>
        ) : (
          <div>
            <Tooltip title="Filters">
              <Button
                style={{ margin: "20px 15px" }}
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              >
                <FilterOutlined />
              </Button>
            </Tooltip>
            <Sider width={"5%"} />
          </div>
        )}
        <Content>
        <div className="kol-search">
  <Row style={{ width: "100%" ,justifyContent:"space-around"}}>
    {!isMobile && (
      <Col
        xs={{ flex: "100%" }}
        sm={{ flex: "100%" }}
        md={{ flex: "5%" }}
        lg={{ flex: "5%" }}
        xl={{ flex: "5%" }}
      >
        <Radio.Group
          style={{
            display: "flex",
            marginRight: "15px",
            marginLeft: "10px",
            marginBottom: '10px'
          }}
          value={view}
          onChange={(e) => {
            setView(e.target.value);
            setTableFilter({
              ...tableFilterData,
              page: 1,
            });
            setSearchTableFilter({
              ...searchTableFilterData,
              page: 1,
            });
            fetchData(
              {
                page: 1,
                sort_by: searchFlag ? searchTableFilterData?.sortBy : tableFilterData?.sortBy,
                sort_order: searchFlag ? searchTableFilterData?.sortOrder : tableFilterData?.sortOrder,
              },
              "null",
              searchFlag
            );
          }}
        >
          <Radio.Button value="card" className={view === "card" ? "active" : ""}>
            <ProductOutlined />
          </Radio.Button>
          <Radio.Button value="table" className={view === "table" ? "active" : ""}>
            <BarsOutlined />
          </Radio.Button>
        </Radio.Group>
      </Col>
    )}
    <Col
      xs={{ flex: "100%" }}
      sm={{ flex: "100%" }}
      md={{ flex: "100%" }}
      lg={{ flex: "50%" }}
      xl={{ flex: "50%" }}
    >
      <div
        className={classNames("", {
          "search-kol-mobile": windowSize$ === "lg" || windowSize$ === "xl" || windowSize$ === "xxl",
          "search-kol-mobile": windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md",
        })}
      >
        <Space.Compact style={{ width: "100%" }}>
          <Input
            style={{ width: "100%" }}
            size="large"
            placeholder="Search"
            value={searchText}
            onChange={handleSearchInputChange}
            onKeyDown={handleKeypress}
          />
          <Button onClick={submitSearch} style={{ height: "auto" }}>
            <SearchOutlined />
          </Button>
        </Space.Compact>
      </div>
    </Col>
    <Col
      xs={{ flex: "100%" }}
      sm={{ flex: "100%" }}
      md={{ flex: "100%" }}
      lg={{ flex: "39%" }}
      xl={{ flex: "40%" }}
    >
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", height: "100%" }}>
        {userDetails?.role_access?.kolProfiles?.edit && (
          <>
          {showEditButtons?
          <>
          <Button
            style={{
              background: "#000000",
              marginTop: "5px",
              marginLeft: "5px",
              padding:"0 20px"
            }}
            type="primary"
            onClick={() => bulkEdit('cancel')}
          >
            Cancel
          </Button>
          <Button
            style={{
              background: "#000000",
              marginTop: "5px",
              marginLeft: "5px",
              padding:"0 20px"
            }}
            type="primary"
            onClick={() => setShowBulkEditModal(true)}
          >
            Edit
          </Button>
          </>
          :
          <>
          {/* <Button
            style={{
              background: "#000000",
              marginTop: "5px",
              marginLeft: "5px",
              padding:"0 20px"
            }}
            type="primary"
            onClick={() => bulkEdit('bulkEdit')}
          >
            Bulk Edit
          </Button> */}
            <Button
            style={{
              background: "#000000",
              marginTop: "5px",
              marginLeft: "5px",
              padding:"0 20px"
            }}
            type="primary"
            onClick={() => addNew()}
          >
            + Add
          </Button>
          </>
          }

          </>
        )}
      </div>
    </Col>
  </Row>
</div>

          <div
            className="login-root"
            style={{
              padding: "0 10px 10px 10px",
              borderRadius: "15px",
              marginTop: "10px",
              backgroundColor: "transparent",
              height: "calc(100vh - 235px)",
            }}
          >
            <div>
              {/* <Row> */}
              {view === "card" ? (
                <>
                  <Row
                    className="list-container"
                    onScroll={(e) => handleScroll(e, searchFlag)}
                    style={{ padding: "0px" }}
                  >
                    {searchFlag ? (
                      <>
                        {searchStatusData?.data?.length > 0 ? (
                          searchData?.filterData?.map((el, i) => (
                            <Col
                              className="tile"
                              style={{ padding: "0px 1% 0px 0px" }}
                              xs={{ flex: "100%" }}
                              sm={{ flex: "50%" }}
                              md={{ flex: "50%" }}
                              lg={{ flex: "25%" }}
                              xl={{ flex: "25%" }}
                            >
                              <div className="attendee-body">
                                <Card
                                  className="pointer"
                                  style={{
                                    width: "100%",
                                    height: "fit-content",
                                    borderRadius: "4px",
                                  }}
                                  onClick={() =>
                                    onSelectCard(
                                      "profileDetails" + "/" + el.profile_id
                                    )
                                  }
                                >
                                  <div
                                    style={{
                                      textAlign: "center",
                                      margin: "10px 0",
                                    }}
                                  >
                                    {el.full_name !== " " ? (
                                      <Avatar
                                        style={{
                                          backgroundColor: setBg(),
                                          verticalAlign: "middle",
                                        }}
                                        size={100}
                                      >
                                        <span className="avatar-Text">
                                          {el.full_name.split(" ")[0][0]}
                                          {el.full_name.split(" ")[1][0]}
                                        </span>
                                      </Avatar>
                                    ) : (
                                      <Avatar
                                        style={{
                                          //  backgroundColor: setBg(),
                                          verticalAlign: "middle",
                                        }}
                                        size={100}
                                      >
                                        <UserOutlined />
                                      </Avatar>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                    }}
                                  >
                                    <Title
                                      level={4}
                                      style={{
                                        color: "#3D3D3D",
                                        textAlign: "center",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      {el.full_name}
                                    </Title>
                                    <Text
                                      className="text-ellipsis-one-line "
                                      style={{
                                        fontSize: "14px",
                                        color: "black",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {el.designation}
                                    </Text>
                                  </div>
                                  <div className="userData">
                                    <div className="details">
                                      <Text className="label">Professional type</Text>
                                      <Text className="text-center fontWeight-500 value">
                                        {el.degree || '-'}
                                      </Text>
                                    </div>
                                    <div className="details">
                                      <Text className="label">
                                      Title
                                      </Text>
                                      <Text className="text-center fontWeight-500 value">
                                        {el.area_of_expertise|| '-'}
                                      </Text>
                                    </div>
                                    <div className="details">
                                      <Text className="label">Email</Text>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Text className="email value">
                                          {el.email || "-"}
                                        </Text>
                                        <span
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            copyToClipboard(el.email);
                                          }}
                                        >
                                          <CopyOutlined />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="details">
                                      <Text className="label">Mobile</Text>
                                      <Text className="text-center fontWeight-500 value">
                                        {el.phone_number || "-"}
                                      </Text>
                                    </div>
                                  </div>
                                </Card>
                              </div>
                            </Col>
                          ))
                        ) : (
                          <>
                            {searchStatusData?.loading ? (
                              <>
                                <Col
                                  className="center-all"
                                  xs={{ flex: "100%" }}
                                  sm={{ flex: "100%" }}
                                  md={{ flex: "100%" }}
                                  lg={{ flex: "100%" }}
                                  xl={{ flex: "100%" }}
                                >
                                  <Empty
                                    description={
                                      <Typography.Text>
                                        Loading...
                                      </Typography.Text>
                                    }
                                  />
                                </Col>
                              </>
                            ) : (
                              <Col
                                className="center-all"
                                xs={{ flex: "100%" }}
                                sm={{ flex: "100%" }}
                                md={{ flex: "100%" }}
                                lg={{ flex: "100%" }}
                                xl={{ flex: "100%" }}
                              >
                                <Empty
                                  description={
                                    <Typography.Text>
                                      No profiles found
                                    </Typography.Text>
                                  }
                                />
                              </Col>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {statusData?.data?.length > 0 ? (
                          data?.filterData?.map((el, i) => (
                            <Col
                              className="tile"
                              style={{ padding: "0px 1% 0px 0px" }}
                              xs={{ flex: "100%" }}
                              sm={{ flex: "50%" }}
                              md={{ flex: "20%" }}
                              lg={{ flex: "20%" }}
                              xl={{ flex: "20%" }}
                            >
                              <div className="attendee-body">
                                <Card
                                  className="pointer"
                                  style={{
                                    width: "100%",
                                    height: "fit-content",
                                    borderRadius: "4px",
                                  }}
                                  onClick={() =>
                                    onSelectCard(
                                      "profileDetails" + "/" + el.profile_id
                                    )
                                  }
                                >
                                  <div
                                    style={{
                                      textAlign: "center",
                                      margin: "10px 0",
                                    }}
                                  >
                                    {el.full_name !== " " ? (
                                      <Avatar
                                        style={{
                                          backgroundColor: setBg(),
                                          verticalAlign: "middle",
                                        }}
                                        size={100}
                                      >
                                        <span className="avatar-Text">
                                          {el.full_name.split(" ")[0][0]}
                                          {el.full_name.split(" ")[1][0]}
                                        </span>
                                      </Avatar>
                                    ) : (
                                      <Avatar
                                        style={{
                                          //  backgroundColor: setBg(),
                                          verticalAlign: "middle",
                                        }}
                                        size={100}
                                      >
                                        <UserOutlined
                                          style={{ fontSize: "60px" }}
                                        />
                                      </Avatar>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                    }}
                                  >
                                    <Title
                                      level={4}
                                      style={{
                                        color: "#3D3D3D",
                                        textAlign: "center",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      {el.full_name || '-'}
                                    </Title>
                                    <Text
                                      className="text-ellipsis-one-line "
                                      style={{
                                        fontSize: "14px",
                                        color: "black",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {el.designation || '-'}
                                    </Text>
                                  </div>
                                  <div className="userData">
                                    <div className="details">
                                      <Text className="label">Professional type</Text>
                                      <Text className="text-center fontWeight-500 value">
                                        {el.degree|| '-'}
                                      </Text>
                                    </div>
                                    <div className="details">
                                      <Text className="label">
                                      Title
                                      </Text>
                                      <Text className="text-center fontWeight-500 value">
                                        {el.area_of_expertise|| '-'}
                                      </Text>
                                    </div>
                                    <div className="details">
                                      <Text className="label">Email</Text>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Text className="email value">
                                          {el.email || "-"}
                                        </Text>
                                        <span
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            copyToClipboard(el.email);
                                          }}
                                        >
                                          <CopyOutlined />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="details">
                                      <Text className="label">Mobile</Text>
                                      <Text className="text-center fontWeight-500 value">
                                        {el.phone_number || "-"}
                                      </Text>
                                    </div>
                                  </div>
                                </Card>
                              </div>
                            </Col>
                          ))
                        ) : (
                          <>
                            {statusData?.loading ? (
                              <Col
                                className="center-all"
                                xs={{ flex: "100%" }}
                                sm={{ flex: "100%" }}
                                md={{ flex: "100%" }}
                                lg={{ flex: "100%" }}
                                xl={{ flex: "100%" }}
                              >
                                <Empty
                                  description={
                                    <Typography.Text>
                                      Loading...
                                    </Typography.Text>
                                  }
                                />
                              </Col>
                            ) : (
                              <>
                                {" "}
                                <Col
                                  className="center-all"
                                  xs={{ flex: "100%" }}
                                  sm={{ flex: "100%" }}
                                  md={{ flex: "100%" }}
                                  lg={{ flex: "100%" }}
                                  xl={{ flex: "100%" }}
                                >
                                  <Empty
                                    description={
                                      <Typography.Text>
                                        Currenly no profiles available, please
                                        create a KOL profile
                                      </Typography.Text>
                                    }
                                  />
                                </Col>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Row>
                </>
              ) : (
                <>
                  {(searchStatusData?.data && searchStatusData?.data?.length) >
                    0 && (
                    <Table
                      columns={tableColumns}
                      dataSource={searchData?.filterData}
                      pagination={{
                        current: searchTableFilterData.page,
                        pageSize: searchTableFilterData.size,
                        total: searchData?.total_count,
                        onChange: (page, pageSize) => {
                          handleTablePagination({
                            current: page,
                            pageSize,
                          });
                          
                        },
                      }}
                      scroll={{
                        y: "calc(100vh - 328px)",
                      }}
                    />
                  )}
                  {statusData?.data && statusData?.data?.length > 0 && (
                    <Table
                      columns={tableColumns}
                      dataSource={data?.filterData}
                      pagination={{
                        current: tableFilterData.page,
                        pageSize: tableFilterData.size,
                        total: data?.total_count,
                        onChange: (page, pageSize) => {
                          console.log(pageSize,'pageSize')
                          handleTablePagination({
                            current: page,
                            pageSize,
                          });
                        },
                      }}
                      scroll={{
                        y: "calc(100vh - 328px)",
                      }}
                    />
                  )}
                </>
              )}
              <Modal
                style={{ borderRadius: "15px" }}
                onCancel={() => {
                  setShowAttributeModel(false);
                  //reset value and update the old data
                  setSelectedAttributes([...backUpSelectedAttributes]);
                }}
                bodyStyle={{ overflow: 'auto', maxHeight: '40vh' }}
                title={
                  <div>
                    Configure filters
                    <Divider style={{ margin: "15px 0" }} />
                  </div>
                }
                okText="Add"
                footer={
                  <div>
                    <Divider style={{ margin: "15px 0" }} />
                    <Button
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        setShowAttributeModel(false);
                        //reset value and update the old data
                        setSelectedAttributes([...backUpSelectedAttributes]);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type="primary" onClick={handleAddAttributes}>
                      Save
                    </Button>
                  </div>
                }
                open={showAttributeModel}
              >
                {attributes?.map((attribute)=>{
                  return (
                    <p><Checkbox checked={selectedAttributes.includes(attribute?.value)} onChange={(event)=>handleAttributes(event,attribute)}>{attribute?.label}</Checkbox></p>
                  )
                })}
              </Modal>

              {/* Bulk Edit Modal */}
              <Modal title="Edit Users Details" open={showBulkEditModal} onOk={()=>handleBulkEditDetails('save')} onCancel={()=>handleBulkEditDetails('cancel')}>
              Table
            </Modal>
              {/* </Row> */}
            </div>
          </div>
          <Footer>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px 20px",
                marginTop: "10px",
              }}
            >
              <Title className="countWrapper" style={{ margin: '0 10px 0 0' }} level={4} >
                <span className="count">
                  {view === 'card' ? 
                  (searchFlag
                    ? searchData?.data?.length
                    : data?.data?.length)
                    :
                    (
                      searchFlag 
                      ? Math.min(searchTableFilterData.size * searchTableFilterData.page, searchData?.total_count) 
                      : Math.min(tableFilterData.page * tableFilterData.size, data?.total_count)
                    )
                  }
                </span>
                &nbsp; <span className="countText">of</span> &nbsp;
                <span className="count">
                  {searchFlag
                    ? searchData?.total_count
                    : data?.total_count}
                </span>{" "}
                <span className="countText">
                  {" "}
                  {searchData?.filterData?.length === 1 ? (
                    <>Profile </>
                  ) : (
                    <>Profiles</>
                  )}
                </span>
              </Title>
              <Title className="countWrapper" level={4} style={{ margin: 0 }}>
                <span className="countText">Total : &nbsp;</span>
                <span className="count">
                  {searchFlag
                    ? searchData?.total_count
                    : data?.total_count}
                </span>
                &nbsp;
                <span className="countText">
                  {searchData?.filterData?.length === 1 ? (
                    <>Profile </>
                  ) : (
                    <>Profiles</>
                  )}
                </span>
              </Title>
            </div>
          </Footer>
        </Content>
      </Layout>
    </div>
  );
}
