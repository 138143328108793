import { React, useState, useEffect } from "react";
import { Space, Table, Tag, Button, Typography } from "antd";
import {
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Upload,
} from "antd";
import { alertService } from "../../../../Shared/Utils/ToasterUtils";
import { ServiceUtils } from "../../../../Shared/Utils/ServiceUtils";

import { MailOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import "./Drawer.scss";
import { useNavigate, useParams } from 'react-router-dom';
const { Option } = Select;
export default function DrawerComponent({ ...props }) {
  // console.log(props);
  const navigate = useNavigate();
  const [currentState, setCurrentState] = useState(props?.passData);
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [inputs, setInputs] = useState(props?.mailInputs ? props?.mailInputs : {"subject":props?.defaultSubject});
  const [attendeeList, setAttendeeListData] = useState([]);
  const meetingID = props?.meetingID;
  const userDetails = props?.userDetails;
  const followUpDetails = props?.followUpDetails;
  //   const [attendeeTableList, setAttendeeTableList] = useState([]);
  const [attendeeTableList, setAttendeeTableList] = useState({
    engagementAndNotes: [],
    tableData: {
      headerContent: [],
      bodyContent: [],
    },
  });

  const [currentIndex, setCurrentIndex] = useState(props?.currentItem);
  const { id } = useParams();

  useEffect(() => {
    if (currentState === "composeMail") {
      attendeeFilter();
      setOpen(true);
    } else if (currentState === "traceMail") {
      setOpen(true);
      getAttendee();
    } else {
      onClose();
    }
  }, []);

  const handleSelectOnChange = (val, label, key) => {
    const name = key;
    const value = val;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const onProfileSelect = () => {};

  const attendeeFilter = () => {
    let payLoad = {
      conference_id: parseInt(id),
  };
      ServiceUtils.postRequest("attendeeFilter",payLoad).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          setAttendeeListData(response.data.data);
        }
        else if(response.status === 401 ){
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error("Error while fetching Data.");
      }
    );
  };

  const getAttendee = () => {
    // console.log(props);
    let payload = {
      meeting_id: parseInt(props.meetingID),
      followup_id:parseInt(props.followUpID)
    };
    ServiceUtils.postRequest("traceMail", payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          // console.log(response.data)
          response.data?.tableData.headerContent.map((el) => {
            if (el.key === "from") {
              el.render = (_, record) => (
                <Space>
                  {record.from}
                </Space>
              );
            } else if (el.key === "to") {
              el.render = (_, record) => (
                <>
                {record?.to?.map((tag)=>{
                  let color = tag.length > 5 ? 'geekblue' : 'green';
                  if (tag === 'loser') {
                    color = 'volcano';
                  }
                  return(
                    <Tag color={color} key={tag}>
                      <span style={{ color: color, fontWeight: "500" }}>
                        {tag}
                      </span>
                    </Tag>
                  )
                })}
                </>
              );
            }
          });
          setAttendeeTableList(response.data);
        }
        else if(response.status === 401 ){
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error("Error while fetching data.");
      }
    );
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const attachmentChange = (event) => {
      let imageInputJson = [];
      const fileList = event.target.files;
      if (fileList.length > 0) {
        // imageInputJson.imageUploads = [];
        let file = fileList[0];
        let imgData = {};
        // for (let i = 0; i < fileList.length; i++) {
        for (const fileName of Object.values(fileList)) {
          // console.log(fileList,Object.values(fileList));
          const reader = new FileReader();
          let fileType = file.type;
          reader.onload = (frEvent) => {
            const imgContent = frEvent.target['result'];
            if (imgContent.length > 0 ) {
              imgData = {};
              imgData['file_name'] = file.name;
              imgData['data'] = imgContent.split(',')[1];
              imageInputJson.push(imgData);              
              // console.log(imageInputJson[0].data);
            }
          };
          file = fileName;
          reader.readAsDataURL(file);
          // console.log(reader.readAsDataURL(file))
        }
      }
  }

  const multiAttchOnChange = (event) => {
    // console.log(event);
    // console.log(event);
    const name = "attachments";
    const value = event?.fileList;
    setInputs((values) => ({ ...values, [name]: value }));
    // let imageInputJson = [];
    // const fileList = event?.fileList;
    // if (fileList.length > 0) {
    //   // imageInputJson.imageUploads = [];
    //   for (let i = 0; i < fileList.length; i++) {
    //     let file = fileList[0];
    //     for (const fileName of Object.values(fileList)) {
    //       console.log(fileName,Object.values(fileList));
    //       const reader = new FileReader();
    //       let fileType = fileList[i].type;
    //       reader.onload = (frEvent) => {
    //         console.log('test')
    //         const imgContent = frEvent.target['result'];
    //         if (imgContent.length > 0 ) {
    //           let imgData = {};
    //           imgData['file_name'] = fileList[i].name;
    //           imgData['data'] = imgContent.split(',')[1];
    //           imageInputJson.push(imgData); 
    //           if(imageInputJson[0].data){
    //             fileList[i]["data"]=imageInputJson[0]?.data;
    //           }             
    //           console.log(imageInputJson[0].data);
    //         }
    //       };
    //       file= fileName;
    //       reader.readAsDataURL(fileList[i]);
    //       // console.log(reader.readAsDataURL(file))
    //     }
    //   }
    // }
    // console.log(fileList);
  }

  const beforeUpload = (fil, fileList) => {
    // Access file content here and do something with it
    return new Promise((resolve, reject) => {
    console.log(fil)
    console.log(fileList);
    let imageInputJson = [];
    // const fileListt = fileList;
    // console.log(fil.size / 1024 / 1024 <= 5)
    if(fil.size / 1024 / 1024 <= 5){
      // alert('Image must smaller than 5MB!');
      reject(false);
    }
    else{
      alertService.error('Image must smaller than 5MB!')
    }
    if (fileList.length > 0) {
      // imageInputJson.imageUploads = [];
      for (let i = 0; i < fileList.length; i++) {
        let file = fileList[i];
        for (const fileName of Object.values(fileList)) {
          // console.log(fileName,Object.values(fileList));
          const reader = new FileReader();
          let fileType = fileList[i].type;
          reader.onload = (frEvent) => {
            // console.log('test')
            const imgContent = frEvent.target['result'];
            if (imgContent.length > 0 ) {
              let imgData = {};
              imgData['file_name'] = fileList[i].name;
              imgData['data'] = imgContent.split(',')[1];
              imageInputJson.push(imgData); 
              if(imageInputJson[0].data){
                fileList[i]["data"]=imageInputJson[0]?.data;
              }             
              // console.log(imageInputJson[0].data);
            }
          };
          file= fileName;
          reader.readAsDataURL(file);
          // console.log(reader.readAsDataURL(file))
        }
      }
    }
    // console.log(fileList);

    // Prevent upload
    // return false
    })
  }

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    // console.log(Form);
    props.closeDrawer();
    setOpen(false);
    if (currentState === "composeMail") {
      if (validateInputs()) {
        //
      } 
      else {
        props.setValidatedMailFlag(false);
      }
      // setValidateMailFlag
    }
  };

  const validateInputs = () => {
    let flag = false;
    if (inputs) {
      // console.log(inputs);
      let count = 0;
      for (const [key, value] of Object.entries(inputs)) {
        // if (typeof (value) === "object" && value?.length) {
        //     count = count + 1;
        // }
        // else if ((typeof (value) === "string" || typeof (value) === "number") && value) {
        //     count = count + 1;
        // }
        if (typeof value === "object" && value?.length && key === "to") {
          count = count + 1;
        } else if (
          (typeof value === "string" || typeof value === "number") &&
          value
        ) {
          count = count + 1;
        }
        // console.log(`Key: ${key}, Value: ${value}`);
      }
      // console.log(count);
      if (count >= 3) {
        flag = true;
      } else {
        alertService.error("Please fill all required fields");
      }
    } else {
      alertService.error("Please fill all required fields");
    }
    return flag;
  };

  const followUpService=(payload)=>{
    ServiceUtils.postRequest('saveFollowup', payload).then(
      (response) => {
          if (response.status === 200 && response?.data.status) {
              // getListOfFollowups();
              // setFormData({
              //     action_on: '',
              //     action_assigned_to: '',
              //     action_status: '',
              //     id: '',
              //     type: 'save',
              //     composeMail:''
              // });
              // SetMailComposeInputs();
              props.setValidatedMailFlag(true);
              props.setMailInputs(inputs,currentIndex);
              setOpen(false);
          }
          else if(response.status === 401 ){
            navigate('/logout');
          }
      },
      (error) => {
          alertService.error('Error while fetching Objective.')
      }
  );
  }

  const onSaveFollowUp = (data) => {
    let tempData = JSON.parse(JSON.stringify(data));
    if(!tempData?.mail_type){
      // console.log(tempData?.mail_type)
      tempData.mail_type = 1 
    }
    // console.log(tempData)
    let payload={
      data:{
        ...tempData,
        meetingId:meetingID,
        user_email:userDetails?.user_email,
        user_id:userDetails?.user_id,
        type:data?.id ?'edit':'save',
        user_name:userDetails?.user_name,
        created_time:new Date,
        created_timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      attachments:inputs?.attachments,
    }
    // console.log(payload)
    followUpService(payload)
  }

  const onSubmit = () => {
    // console.log(inputs);
    // for (var va of inputs?.attachments?.values()) {
    //   console.log(va); 
    // }
    let tempData = JSON.parse(JSON.stringify(followUpDetails))
    if (validateInputs()) {
      // props.setValidatedMailFlag(true);
      // props.setMailInputs(inputs,currentIndex);
      // setOpen(false);
      tempData["mail_data"]=inputs;
      onSaveFollowUp(tempData)
    }
  };

  const filterOption = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };

  const filter = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };

  const filterBCC = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };

  const some = (data) => {
    console.log(data);
  }

  const propss = {
    name: 'file',
    // action: 
    onChange(info) {
    //   if (info.file.status !== 'uploading') {
    //     console.log(info.file, info.fileList);
    //   }
    console.log(info)
      if (info.file.status === 'done') {
        alert("File uploaded successfully"); 
      } else if (info.file.status === 'error') {
        // warning("File upload failed"); 
      }
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 500);
  };

  return (
    <>
      <div style={{ padding: "20px" }}>
        {/* <Button type="primary" onClick={showDrawer} icon={<MailOutlined />}>
          Edit Mail
        </Button> */}
        {currentState === "composeMail" && (
          <>
            <Drawer
              title="Enter Mail Details"
              // width={720}
              size="default"
              onClose={onClose}
              open={open}
              styles={{
                body: {
                  paddingBottom: 80,
                },
              }}
              // extra={
              //   <Space>
              //     <Button onClick={onClose}>Cancel</Button>
              //     <Button onClick={onClose} type="primary">
              //       Submit
              //     </Button>
              //   </Space>
              // }
              footer={
                <Space>
                  <Button onClick={onSubmit} className="edit-meet-button" type="primary">
                    Submit
                  </Button>
                  <Button onClick={onClose}>Cancel</Button>
                </Space>
              }
            >
              <Form layout="vertical" hideRequiredMark>
                <div className="form-item-mail">
                  <label class="text-only text-ellipsis-one-line-black ">
                    Subject
                  </label>
                  <Input.TextArea
                    autoSize
                    style={{ marginTop: "5px" }}
                    name="subject"
                    rows={2}
                    placeholder="Please enter Subject"
                    onChange={handleChange}
                    value={inputs?.subject ? inputs?.subject : props?.defaultSubject}
                  />
                </div>
                <div className="form-item-mail">
                  <label class="text-only text-ellipsis-one-line-black ">
                    To
                  </label>
                  <Select
                    mode="multiple"
                    allowClear
                    name="to"
                    value={inputs?.to}
                    onChange={(value, label) => {
                      handleSelectOnChange(value, label, "to");
                    }}
                    placeholder="Please select To recipients"
                    options={attendeeList}
                    style={{ width: "100%", marginTop: "5px" }}
                    showSearch
                    filterOption={filter}
                  />
                </div>
                <div className="form-item-mail">
                  <label class="text-only text-ellipsis-one-line-black ">
                    Cc
                  </label>
                  <Select
                    mode="multiple"
                    allowClear
                    name="cc"
                    value={inputs?.cc}
                    onChange={(value, label) => {
                      handleSelectOnChange(value, label, "cc");
                    }}
                    placeholder="Please select CC recipients"
                    options={attendeeList}
                    style={{ width: "100%", marginTop: "5px" }}
                    showSearch
                    filterOption={filterOption}
                  />
                </div>
                <div className="form-item-mail">
                  <label class="text-only text-ellipsis-one-line-black ">
                    Bcc
                  </label>
                  <Select
                    mode="multiple"
                    allowClear
                    value={inputs?.bcc}
                    name="bcc"
                    onChange={(value, label) => {
                      handleSelectOnChange(value, label, "bcc");
                    }}
                    placeholder="Please select BCC recipients"
                    options={attendeeList}
                    style={{ width: "100%", marginTop: "5px" }}
                    showSearch
                    filterOption={filterBCC}
                  />
                </div>
                <div className="form-item-mail">
                  <label class="text-only text-ellipsis-one-line-black ">
                    Attachments
                  </label>
                  <Upload
                    style={{ marginTop: "5px" }}
                    name="attachments"
                    className="upload-man"
                    onChange={(value)=>{multiAttchOnChange(value)}}
                    beforeUpload={beforeUpload}
                    multiple
                    // action={""}
                    customRequest={dummyRequest}
                    // accept="file"
                  >
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                  </Upload>
                  {/* <Input type="file" onChange={(value)=>{attachmentChange(value)}}/> */}
                  {/* <input type="file" multiple onChange={attachmentChange} /> */}
                </div>
                <div className="form-item-mail">
                  <label class="text-only text-ellipsis-one-line-black ">
                    Body
                  </label>
                  <Input.TextArea
                    autoSize
                    onChange={handleChange}
                    style={{ marginTop: "5px" }}
                    name="body"
                    rows={2}
                    placeholder="Please enter body"
                  />
                </div>
                {/* <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    name="approver"
                    label="Approver"
                    rules={[
                        {
                        required: true,
                        message: "Please choose the approver",
                        },
                    ]}
                    >
                    <Select placeholder="Please choose the approver">
                        <Option value="jack">Jack Ma</Option>
                        <Option value="tom">Tom Liu</Option>
                    </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    name="dateTime"
                    label="DateTime"
                    rules={[
                        {
                        required: true,
                        message: "Please choose the dateTime",
                        },
                    ]}
                    >
                    <DatePicker.RangePicker
                        style={{
                        width: "100%",
                        }}
                        getPopupContainer={(trigger) => trigger.parentElement}
                    />
                    </Form.Item>
                </Col>
                </Row> */}
              </Form>
            </Drawer>
          </>
        )}
        {currentState === "traceMail" && (
          <>
            <Drawer
              title="Mail Details"
              width={"80%"}
              // size="large"
              onClose={onClose}
              open={open}
              styles={{
                body: {
                  paddingBottom: 80,
                },
              }}
              // extra={
              //   <Space>
              //     <Button onClick={onClose}>Cancel</Button>
              //     <Button onClick={onClose} type="primary">
              //       Submit
              //     </Button>
              //   </Space>
              // }
              //   footer={
              //     <Space>
              //       <Button onClick={onSubmit} type="primary">
              //         Submit
              //       </Button>
              //       <Button onClick={onClose}>Cancel</Button>
              //     </Space>
              //   }
            >
              <div
                style={{
                  marginBottom: "10px",
                  width: "calc(100% - 3%)",
                  overflow: "auto",
                }}
              >
                <Table
                  columns={attendeeTableList.tableData.headerContent}
                  dataSource={attendeeTableList.tableData.bodyContent}
                  bordered
                  pagination={false}
                />
              </div>
            </Drawer>
          </>
        )}
      </div>
      {/* <div>
        <ul>
          {items.map((item) => (
            <li key={item.id}>{item.name}</li>
          ))}
        </ul>
        {isLoading && <p>Loading...</p>}
        {error && <p>Error: {error.message}</p>}
      </div> */}
    </>
  );
}
